"use client";
import { jsx as e, jsxs as t } from "react/jsx-runtime";
import s from "../../../assets/styles/theme.js";
import m from "../IconButton/IconButton.js";
import { StyledPopupOverlay as f, StyledPopupBox as y, StyledPopupHeader as x, StyledPopupHeaderText as h } from "./Popup.styles.js";
function k({
  children: o,
  title: l,
  onClose: a,
  dataTest: r,
  disabled: p = !1,
  bottomPositionOnMobile: d = "0px",
  wide: n = !1,
  flex: i = !1,
  width: u = "default",
  isHeaderVisible: c = !0
}) {
  return /* @__PURE__ */ e(f, { "data-element": "popup-overlay", children: /* @__PURE__ */ t(
    y,
    {
      "data-element": "popup-box",
      bottomPositionOnMobile: d,
      wide: n,
      flex: i,
      "data-width": u,
      "data-test": r,
      children: [
        c && /* @__PURE__ */ t(x, { children: [
          /* @__PURE__ */ e(h, { children: l }),
          /* @__PURE__ */ e(
            m,
            {
              color: s.colors.grayscale.dark,
              "data-test": "button-close",
              disabled: p,
              iconSize: 15,
              iconType: "Close",
              label: "Zamknij",
              onClick: a
            }
          )
        ] }),
        o
      ]
    }
  ) });
}
export {
  k as default
};
