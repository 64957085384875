import { jsx as o } from "react/jsx-runtime";
import { s as a } from "../../../index-CARpW-rC.js";
import t from "../../../assets/styles/theme.js";
import '../../../assets/HelperText.css';var n = /* @__PURE__ */ ((r) => (r.Hint = "Hint", r.Error = "Error", r))(n || {});
const l = () => ({
  type: r
}) => {
  switch (r) {
    case "Error":
      return t.colors.complimentary.red;
    default:
      return t.colors.grayscale.dark;
  }
}, c = /* @__PURE__ */ a("span")({
  name: "StyledText",
  class: "sww8wb5",
  propsAsIs: !1,
  vars: {
    "sww8wb5-0": [l()]
  }
});
function f({
  text: r,
  type: e = "Hint",
  dataTest: s
}) {
  return /* @__PURE__ */ o(c, {
    "data-test": s,
    type: e,
    children: r
  });
}
export {
  n as HelperTextType,
  f as default
};
