"use client";
import { useState as i, useEffect as d } from "react";
function f(c) {
  const [e, n] = i(c), [r, s] = i(!1);
  return d(() => {
    if (!e || typeof window > "u")
      return;
    e.includes("@media ") && n(e.replace("@media ", ""));
    const a = (u) => {
      s(u.matches);
    }, t = window.matchMedia(e);
    return s(t.matches), t.addEventListener("change", a), () => {
      t.removeEventListener("change", a);
    };
  }, [e]), [r, n];
}
export {
  f as default
};
