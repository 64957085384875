import { s } from "../../../index-CARpW-rC.js";
import '../../../assets/CircularProgressBar.css';const l = () => ({
  size: e
}) => e, a = /* @__PURE__ */ s("div")({
  name: "StyledCircularProgressBar",
  class: "s1cqhqx2",
  propsAsIs: !1,
  vars: {
    "s1cqhqx2-0": [l()]
  }
}), c = /* @__PURE__ */ s("circle")({
  name: "StyledCircleBackground",
  class: "s19egj24",
  propsAsIs: !0
}), t = /* @__PURE__ */ s("circle")({
  name: "StyledCircleValue",
  class: "sb07doo",
  propsAsIs: !0
}), o = /* @__PURE__ */ s("span")({
  name: "StyledValueText",
  class: "sydm80",
  propsAsIs: !1
});
export {
  c as StyledCircleBackground,
  t as StyledCircleValue,
  a as StyledCircularProgressBar,
  o as StyledValueText
};
