"use client";
import { jsxs as d, jsx as o } from "react/jsx-runtime";
import a from "../../../assets/styles/theme.js";
import { StyledAlertBox as I, StyledIcon as h, StyledIconButton as m } from "./AlertBox.styles.js";
function g(r, e) {
  if (e)
    return e;
  switch (r) {
    case "Success":
      return "Success";
    case "Error":
      return "Error";
    case "ErrorGlobal":
      return "Error";
    case "Info":
    case "InfoLight":
      return "Info";
    case "Legal":
      return "Legal";
    default:
      return null;
  }
}
const p = "Zamknij", x = "alert-box";
function S({
  message: r,
  className: e,
  dataTest: c = x,
  hasCloseButton: l = !1,
  fixedPositioned: s = !1,
  noBorderRadius: i = !1,
  type: t = "InfoLight",
  customIcon: u,
  onClose: f
}) {
  const n = g(t, u);
  return /* @__PURE__ */ d(
    I,
    {
      className: e,
      "data-test": c,
      fixedPositioned: s,
      hasCloseButton: l,
      noBorderRadius: i,
      role: "alert",
      type: t,
      children: [
        n ? /* @__PURE__ */ o(h, { color: t === "InfoLight" ? a.colors.primary.default : "inherit", iconType: n }) : null,
        /* @__PURE__ */ o("p", { children: r }),
        l && /* @__PURE__ */ o(
          m,
          {
            color: a.colors.grayscale.white,
            dataTest: "button-closeAlert",
            iconType: "Close",
            label: p,
            onClick: f
          }
        )
      ]
    }
  );
}
export {
  S as default
};
