"use client";
import { jsxs as a, jsx as r } from "react/jsx-runtime";
import { useState as p } from "react";
import d from "./assets/styles/theme.js";
import m from "./components/atoms/Icon/Icon.js";
import { StyledTooltipBox as g, StyledTooltip as h, StyledTip as T, StyledIconButton as y, StyledTooltipTrigger as u } from "./components/atoms/Tooltip/Tooltip.styles.js";
import { buttonLabel as S } from "./components/atoms/Tooltip/Tooltip.constants.js";
var f = /* @__PURE__ */ ((e) => (e.Left = "Left", e.Right = "Right", e))(f || {}), l = /* @__PURE__ */ ((e) => (e.Default = "default", e.Hover = "hover", e))(l || {});
function b({ children: e, setIsOpen: t }) {
  return /* @__PURE__ */ r(u, { onClick: () => t((i) => !i), children: e });
}
function v({ children: e, setIsOpen: t }) {
  return /* @__PURE__ */ r(u, { onMouseEnter: () => t(!0), onMouseLeave: () => t(!1), children: e });
}
function k({
  children: e,
  tip: t,
  position: i = f.Right,
  variant: o = l.Default
}) {
  const [s, n] = p(!1), c = o === l.Default ? b : v;
  return /* @__PURE__ */ a(g, { children: [
    /* @__PURE__ */ r(c, { setIsOpen: n, children: e }),
    s && /* @__PURE__ */ a(h, { "data-position": i, "data-variant": o, children: [
      /* @__PURE__ */ r(T, { "data-variant": o, children: t }),
      o === l.Default && /* @__PURE__ */ r(y, { onClick: () => n(!1), "aria-label": S, type: "button", children: /* @__PURE__ */ r(m, { color: d.colors.grayscale.white, iconType: "Close", size: 12 }) })
    ] })
  ] });
}
export {
  k as T,
  f as a,
  l as b
};
