import { jsx as d } from "react/jsx-runtime";
import { StyledBadge as n } from "./components/atoms/Badge/Badge.styles.js";
var e = /* @__PURE__ */ ((t) => (t.Default = "default", t.Novelty = "novelty", t.Outlined = "outlined", t))(e || {});
function m({
  children: t,
  bgColor: a,
  textBold: u,
  textColor: l,
  dataTest: r,
  size: o = "default",
  variant: f = e.Default
}) {
  return t ? /* @__PURE__ */ d(
    n,
    {
      bgColor: a,
      "data-test": r,
      textBold: u,
      textColor: l,
      "data-size": o,
      "data-variant": f,
      children: t
    }
  ) : null;
}
export {
  m as B,
  e as a
};
