import { jsx as a } from "react/jsx-runtime";
import { StyledOption as d } from "./Option.styles.js";
function s({ children: t, value: n, id: e, onChange: o, isSelected: i }) {
  const r = () => {
    o();
  }, l = (p) => {
    p.key === "Enter" && o();
  };
  return /* @__PURE__ */ a(
    d,
    {
      onClick: r,
      "data-test": `option-${n}`,
      id: e,
      onKeyDown: l,
      role: "option",
      tabIndex: 0,
      isSelected: i,
      children: t
    }
  );
}
export {
  s as default
};
