import { jsxs as f, jsx as e } from "react/jsx-runtime";
import m, { CounterVariant as o } from "./components/atoms/Counter/Counter.js";
import { StyledContent as c, StyledIconLeft as h, StyledChildrenBox as p, StyledIconRight as x } from "./components/atoms/Button/Button.styles.js";
var S = /* @__PURE__ */ ((r) => (r.Default = "Default", r.White = "White", r))(S || {}), s = /* @__PURE__ */ ((r) => (r.Primary = "Primary", r.Secondary = "Secondary", r.Ghost = "Ghost", r.Link = "Link", r.Text = "Text", r))(s || {}), v = /* @__PURE__ */ ((r) => (r.S = "S", r.M = "M", r.L = "L", r))(v || {});
function T({
  children: r,
  counter: a,
  variant: y,
  iconColor: i,
  iconLeft: t,
  iconRight: n,
  isLoading: l = !1,
  strictLine: d = !1
}) {
  return /* @__PURE__ */ f(c, { isLoading: l, children: [
    t && /* @__PURE__ */ e(h, { iconType: t, color: i }),
    d ? /* @__PURE__ */ e(p, { children: r }) : r,
    a !== void 0 && /* @__PURE__ */ e(
      m,
      {
        value: a,
        variant: y === s.Primary ? o.Light : o.Dark
      }
    ),
    n && /* @__PURE__ */ e(x, { iconType: n })
  ] });
}
export {
  S as B,
  s as a,
  v as b,
  T as c
};
