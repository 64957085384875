"use client";
import { jsxs as d, jsx as o } from "react/jsx-runtime";
import { forwardRef as i } from "react";
import { s as m } from "../../../index-CARpW-rC.js";
import s from "../Icon/Icon.js";
import '../../../assets/Anchor.css';const y = () => ({
  caption: r
}) => r ? "12px" : "14px", h = /* @__PURE__ */ m("a")({
  name: "StyledAnchor",
  class: "srp2kky",
  propsAsIs: !1,
  vars: {
    "srp2kky-0": [y()]
  }
}), A = "anchor", x = i(({
  children: r,
  href: e,
  caption: t = !1,
  leftArrow: a,
  rightArrow: n,
  target: p = "_self",
  ariaLabel: c,
  rel: l = "noopener"
}, f) => /* @__PURE__ */ d(h, {
  ref: f,
  "aria-label": c,
  caption: t,
  "data-test": A,
  href: e,
  rel: l,
  target: p,
  children: [a && /* @__PURE__ */ o(s, {
    iconType: "ArrowLeft"
  }), r, n && /* @__PURE__ */ o(s, {
    iconType: "ArrowRight"
  })]
}));
x.displayName = "Anchor";
export {
  x as default
};
