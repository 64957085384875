"use client";
import { jsx as o, jsxs as i } from "react/jsx-runtime";
import { useState as p, useEffect as u } from "react";
import { s as t } from "../../../index-CARpW-rC.js";
import f from "../IconButton/IconButton.js";
import y from "../../../assets/styles/theme.js";
import '../../../assets/Modal.css';const v = 25, S = 300, h = () => ({
  isOpen: e
}) => e ? "visible" : "hidden", M = () => ({
  isOpen: e
}) => e ? 1 : 0, k = /* @__PURE__ */ t("div")({
  name: "StyledModalWrapper",
  class: "s1g967r7",
  propsAsIs: !1,
  vars: {
    "s1g967r7-0": [h()],
    "s1g967r7-1": [M()]
  }
}), g = /* @__PURE__ */ t("div")({
  name: "StyledModal",
  class: "s159ic5s",
  propsAsIs: !1
}), E = /* @__PURE__ */ t("div")({
  name: "StyledModalHeader",
  class: "somvtop",
  propsAsIs: !1
}), I = /* @__PURE__ */ t("h2")({
  name: "StyledModalTitle",
  class: "sc546fz",
  propsAsIs: !1
});
function O({
  dataTest: e,
  title: l,
  children: c,
  onClose: m
}) {
  const [n, d] = p(!1), a = () => {
    d(!1), setTimeout(() => m(), S);
  }, r = (s) => {
    s.key === "Escape" && a();
  };
  return u(() => {
    const s = setTimeout(() => d(!0), v);
    return window.addEventListener("keydown", r), document.documentElement.dataset.modalOpen = "true", () => {
      clearInterval(s), window.removeEventListener("keydown", r), delete document.documentElement.dataset.modalOpen;
    };
  }, []), /* @__PURE__ */ o(k, {
    "aria-modal": "true",
    isOpen: n,
    onClick: a,
    role: "dialog",
    children: /* @__PURE__ */ i(g, {
      "data-test": e,
      onClick: (s) => s.stopPropagation(),
      children: [/* @__PURE__ */ i(E, {
        children: [l ? /* @__PURE__ */ o(I, {
          children: l
        }) : null, /* @__PURE__ */ o(f, {
          color: y.colors.grayscale.dark,
          disabled: !n,
          iconSize: 14,
          iconType: "Close",
          label: "Zamknij",
          onClick: a
        })]
      }), /* @__PURE__ */ o("div", {
        children: c
      })]
    })
  });
}
export {
  O as default
};
