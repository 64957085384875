"use client";
import { jsxs as c, jsx as e } from "react/jsx-runtime";
import { s } from "../../../index-CARpW-rC.js";
import u from "../../atoms/RadioInput/RadioInput.js";
import f from "../../atoms/Paragraph/Paragraph.js";
import '../../../assets/RadioField.css';const h = /* @__PURE__ */ s("div")({
  name: "StyledWrapper",
  class: "s1l8hmqw",
  propsAsIs: !1
}), y = () => u, I = /* @__PURE__ */ s(y())({
  name: "StyledRadioInput",
  class: "sa2dvek",
  propsAsIs: !0
});
function v({
  checked: t,
  dataTest: a,
  disabled: o,
  id: r,
  label: l,
  maxCharacters: p,
  name: d,
  required: n,
  value: i,
  onChange: m
}) {
  return /* @__PURE__ */ c(h, {
    children: [/* @__PURE__ */ e(I, {
      checked: t,
      dataTest: a,
      disabled: o,
      id: r,
      name: d,
      onChange: m,
      required: n,
      value: i
    }), /* @__PURE__ */ e("label", {
      htmlFor: r,
      children: /* @__PURE__ */ e(f, {
        maxCharacters: p,
        children: l
      })
    })]
  });
}
export {
  v as default
};
