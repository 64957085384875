"use client";
import { jsxs as d, jsx as t } from "react/jsx-runtime";
import { useState as H } from "react";
import { b as L } from "../../../Input-DJu9GhhH.js";
import { StyledInputWrapper as S } from "../../atoms/Input/Input.styles.js";
import E, { LabelVariant as p } from "../../atoms/Label/Label.js";
import c, { HelperTextType as m } from "../../atoms/HelperText/HelperText.js";
import g from "./PasswordRules.js";
import { StyledFieldWrapper as j, StyledInput as k, StyledIconButton as B } from "./PasswordInput.styles.js";
const C = "text-errorMessage", D = "text-hintMessage", F = "Pokaż hasło", M = "Ukryj hasło";
function A({
  autoComplete: u,
  error: s = "",
  hint: a = "",
  label: f,
  name: e,
  value: h,
  onChange: y,
  variant: n,
  inputSize: i,
  readOnly: x,
  placeholder: w,
  rules: l
}) {
  const [o, T] = H(!0), b = o ? F : M, r = !!s, P = !!a;
  return /* @__PURE__ */ d(j, { "data-test": `field-${e}`, inputSize: i, readOnly: x, children: [
    /* @__PURE__ */ d(S, { children: [
      /* @__PURE__ */ t(
        k,
        {
          id: e,
          type: o ? "password" : "text",
          autoComplete: u,
          name: e,
          value: h,
          variant: n,
          hasError: r,
          onChange: y,
          inputSize: i,
          placeholder: w
        }
      ),
      /* @__PURE__ */ t(
        E,
        {
          hasError: r,
          htmlFor: e,
          variant: n === L.Compact ? p.Floating : p.Default,
          children: f
        }
      ),
      /* @__PURE__ */ t(
        B,
        {
          iconSize: 24,
          iconType: o ? "Eye" : "EyeCrossed",
          label: b,
          onClick: () => T((I) => !I)
        }
      )
    ] }),
    r && /* @__PURE__ */ t(c, { dataTest: C, text: s, type: m.Error }),
    P && /* @__PURE__ */ t(c, { dataTest: D, text: a, type: m.Hint }),
    l ? /* @__PURE__ */ t(g, { rules: l }) : null
  ] });
}
export {
  A as default
};
