import { s as a } from "../../../index-CARpW-rC.js";
import s from "../../../assets/styles/theme.js";
import '../../../assets/MultilineInput.css';const r = () => ({
  collapsed: e
}) => e ? "absolute" : "relative", t = () => ({
  collapsed: e
}) => e ? "hidden" : "initial", o = () => ({
  collapsed: e
}) => e ? 0 : "auto", l = () => ({
  collapsed: e
}) => e ? 0 : 1, y = /* @__PURE__ */ a("div")({
  name: "StyledFieldWrapper",
  class: "s1aek42y",
  propsAsIs: !1,
  vars: {
    "s1aek42y-0": [r()],
    "s1aek42y-1": [t()],
    "s1aek42y-2": [o()],
    "s1aek42y-3": [l()]
  }
}), p = () => ({
  hasError: e
}) => e ? s.colors.complimentary.red : s.colors.grayscale.dark, d = () => ({
  hasError: e
}) => e ? s.colors.complimentary.red : s.colors.primary.default, i = /* @__PURE__ */ a("textarea")({
  name: "StyledTextarea",
  class: "s1dbs6za",
  propsAsIs: !1,
  vars: {
    "s1dbs6za-0": [p()],
    "s1dbs6za-1": [d()]
  }
});
export {
  y as StyledFieldWrapper,
  i as StyledTextarea
};
