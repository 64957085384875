import { s } from "../../../index-CARpW-rC.js";
import t from "../Icon/Icon.js";
import '../../../assets/Button.css';const d = "b14qiyz3", n = () => ({
  isLoading: e
}) => e ? 0 : 1, p = /* @__PURE__ */ s("span")({
  name: "StyledContent",
  class: "sm8uzh7",
  propsAsIs: !1,
  vars: {
    "sm8uzh7-0": [n()]
  }
}), r = /* @__PURE__ */ s("span")({
  name: "StyledChildrenBox",
  class: "s1u5ksyd",
  propsAsIs: !1
}), o = () => t, m = /* @__PURE__ */ s(o())({
  name: "StyledIconLeft",
  class: "smborsn",
  propsAsIs: !0
}), l = () => t, y = /* @__PURE__ */ s(l())({
  name: "StyledIconRight",
  class: "sb30xq3",
  propsAsIs: !0
}), i = /* @__PURE__ */ s("div")({
  name: "StyledDisabledOnClick",
  class: "s9h1mc3",
  propsAsIs: !1
});
export {
  d as ButtonClass,
  r as StyledChildrenBox,
  p as StyledContent,
  i as StyledDisabledOnClick,
  m as StyledIconLeft,
  y as StyledIconRight
};
