"use client";
import { jsxs as o, Fragment as i, jsx as f } from "react/jsx-runtime";
import { useState as m } from "react";
import { s as a } from "../../../index-CARpW-rC.js";
import '../../../assets/Paragraph.css';const I = /* @__PURE__ */ a("p")({
  name: "StyledParagraph",
  class: "svudj9o",
  propsAsIs: !1
}), y = /* @__PURE__ */ a("button")({
  name: "StyledInlineButton",
  class: "s15fepdh",
  propsAsIs: !1
}), g = "więcej", T = "mniej";
function B({
  children: t,
  maxCharacters: e = Number.POSITIVE_INFINITY,
  isInlineField: l,
  openText: r = g,
  hideText: u = T
}) {
  const n = typeof t == "string" && t.length > e, [s, c] = m(n), d = typeof t == "string" && `${t.slice(0, e)}…`;
  return /* @__PURE__ */ o(I, {
    "data-inline-field": l,
    children: [s ? d : t, n ? /* @__PURE__ */ o(i, {
      children: ["  ", /* @__PURE__ */ f(y, {
        onClick: () => c((p) => !p),
        type: "button",
        children: s ? r : u
      })]
    }) : null]
  });
}
export {
  B as default
};
