import { s } from "../../../index-CARpW-rC.js";
import '../../../assets/Tooltip.css';const t = /* @__PURE__ */ s("div")({
  name: "StyledTooltipBox",
  class: "s1vf1jxb",
  propsAsIs: !1
}), e = /* @__PURE__ */ s("div")({
  name: "StyledTooltip",
  class: "sip11pf",
  propsAsIs: !1
}), l = /* @__PURE__ */ s("button")({
  name: "StyledTooltipTrigger",
  class: "s19fb6sv",
  propsAsIs: !1
}), p = /* @__PURE__ */ s("span")({
  name: "StyledTip",
  class: "shgd0w5",
  propsAsIs: !1
}), a = /* @__PURE__ */ s("button")({
  name: "StyledIconButton",
  class: "s8seeve",
  propsAsIs: !1
});
export {
  a as StyledIconButton,
  p as StyledTip,
  e as StyledTooltip,
  t as StyledTooltipBox,
  l as StyledTooltipTrigger
};
