import { s as e } from "../../../index-CARpW-rC.js";
import '../../../assets/Autocomplete.css';const l = /* @__PURE__ */ e("div")({
  name: "StyledAutocompleteWrapper",
  class: "s105jx0k",
  propsAsIs: !1
}), p = /* @__PURE__ */ e("h3")({
  name: "StyledGroupHeadline",
  class: "sb216qe",
  propsAsIs: !1
}), t = /* @__PURE__ */ e("span")({
  name: "StyledOptionLabel",
  class: "sj1nnhg",
  propsAsIs: !1
});
export {
  l as StyledAutocompleteWrapper,
  p as StyledGroupHeadline,
  t as StyledOptionLabel
};
