const t = -1, a = "text-errorMessage", n = "text-hintMessage", s = 31536e6;
const e = Date.now(), o = new Date(e + 31536e8), r = new Date(e - 31536e8), d = 10;
export {
  o as defaultMaxDate,
  r as defaultMinDate,
  a as errorDataTest,
  n as hintDataTest,
  d as inputTimeoutDelay,
  s as oneYear,
  t as skipTabIndex,
  e as today
};
