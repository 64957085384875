import { useState as c, useEffect as s } from "react";
const r = 250, d = (e, t = r) => {
  const [o, u] = c(e);
  return s(() => {
    const n = setTimeout(() => {
      u(e);
    }, t);
    return () => {
      clearTimeout(n);
    };
  }, [e, t]), o;
};
export {
  d as default
};
