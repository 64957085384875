"use client";
import { jsx as r } from "react/jsx-runtime";
import { s as u } from "../../../index-CARpW-rC.js";
import '../../../assets/RadioInput.css';const f = /* @__PURE__ */ u("input")({
  name: "StyledRadioInput",
  class: "s1uvvya4",
  propsAsIs: !1
});
function c({
  ariaLabel: t,
  checked: a,
  className: s,
  dataTest: e,
  disabled: o,
  id: d,
  name: i,
  required: l,
  value: n,
  onChange: p
}) {
  return /* @__PURE__ */ r(f, {
    "aria-label": t,
    checked: a,
    className: s,
    "data-test": e,
    disabled: o,
    id: d,
    name: i,
    onChange: p,
    required: l,
    type: "radio",
    value: n
  });
}
export {
  c as default
};
