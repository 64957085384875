"use client";
import { jsxs as p, jsx as i } from "react/jsx-runtime";
import { useRef as z, useState as h, useCallback as A, useEffect as H } from "react";
import O from "./components/atoms/Icon/Icon.js";
import j from "./hooks/use-mutation-observable.js";
import w from "./components/atoms/IconButton/IconButton.js";
import E from "./assets/styles/theme.js";
import { StyledAccordionButton as D, StyledAccordion as N, StyledAccordionVisibleContentWrapper as P, StyledAccordionVisibleContent as Q, StyledAccordionHiddenContent as U } from "./components/molecules/Accordion/Accordion.styles.js";
import { dataTestsIds as c } from "./components/molecules/Accordion/Accordion.constants.js";
var C = /* @__PURE__ */ ((n) => (n.PRIMARY = "primary", n.SECONDARY = "secondary", n.TERTIARY = "tertiary", n.QUATERNARY = "quaternary", n))(C || {});
const { PRIMARY: b, TERTIARY: q, QUATERNARY: M } = C;
function L({
  visibleContent: n,
  hiddenContent: g,
  className: I,
  dataTest: S = c.accordion,
  variant: e = b,
  showMoreText: s,
  showLessText: l,
  iconColor: B = E.colors.primary.default
}) {
  const o = z(null), [t, T] = h(!1), [Y, k] = h(""), v = e === b, x = e === q, u = e === M, m = v || x, f = s && l, y = (d) => {
    d.preventDefault(), T((a) => !a);
  }, R = A(
    () => /* @__PURE__ */ p(
      D,
      {
        "aria-controls": "content-section",
        "aria-expanded": t,
        "data-test": c.accordionExpandButton,
        "data-variant": e,
        isOpen: t,
        onClick: y,
        role: "button",
        children: [
          /* @__PURE__ */ i("span", { children: t ? l : s }),
          /* @__PURE__ */ i(O, { color: E.colors.primary.default, iconType: "CheveronDown", size: 24 })
        ]
      }
    ),
    [t, l, s, e]
  ), r = A(() => {
    var a;
    const d = `${(a = o == null ? void 0 : o.current) == null ? void 0 : a.scrollHeight}px`;
    k(d);
  }, []);
  return j({ targetEl: o.current, callBack: r }), H(() => (r(), window.addEventListener("resize", r), () => {
    window.removeEventListener("resize", r);
  }), [r]), /* @__PURE__ */ p(N, { className: I, "data-test": S, "data-variant": e, children: [
    /* @__PURE__ */ p(
      P,
      {
        "data-test": c.accordionVisible,
        "data-variant": e,
        onClick: m ? y : void 0,
        children: [
          /* @__PURE__ */ i(Q, { "data-variant": e, children: n }),
          f && u ? R() : null,
          m ? /* @__PURE__ */ i(
            w,
            {
              "aria-controls": "content-section",
              "aria-expanded": t,
              color: B,
              dataTest: c.accordionExpandButton,
              iconSize: 24,
              iconType: "CheveronDown",
              inverted: t,
              label: t ? "Rozwiń sekcje" : "Zwiń sekcje"
            }
          ) : null
        ]
      }
    ),
    /* @__PURE__ */ i(
      U,
      {
        ref: o,
        isOpen: t,
        height: Y,
        "data-test": c.accordionHidden,
        "data-variant": e,
        id: "content-section",
        role: "region",
        children: g
      }
    ),
    f && !u ? R() : null
  ] });
}
export {
  L as A,
  C as a
};
