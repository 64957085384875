import { jsx as e } from "react/jsx-runtime";
import { s as m } from "../../../index-CARpW-rC.js";
import y from "../Icon/Icon.js";
import { I as v } from "../../../Icon.models-BZwQKyud.js";
import o from "../../../assets/styles/theme.js";
import '../../../assets/IconButton.css';const I = () => ({
  inverted: t
}) => t ? "rotate(-180deg)" : "rotate(0)", g = () => ({
  backgroundColor: t
}) => t, x = () => ({
  hasBackgroundColor: t
}) => t ? 1 : 0, b = /* @__PURE__ */ m("button")({
  name: "StyledIconButton",
  class: "sol8aqg",
  propsAsIs: !1,
  vars: {
    "sol8aqg-0": [I()],
    "sol8aqg-1": [g()],
    "sol8aqg-2": [x()]
  }
}), h = o.colors.primary.default, B = 12, _ = "button-iconButton";
function T({
  className: t,
  dataTest: s = _,
  disabled: a = !1,
  color: l = h,
  hasBackgroundColor: r = !1,
  backgroundColor: n = o.colors.primary.highlight,
  iconSize: c = B,
  iconType: u,
  inverted: f = !1,
  label: i,
  type: p = "button",
  onClick: d
}) {
  return /* @__PURE__ */ e(b, {
    "aria-label": i,
    className: t,
    "data-test": s,
    disabled: a,
    hasBackgroundColor: r,
    backgroundColor: n,
    inverted: f,
    onClick: d,
    type: p,
    "data-icon_button": !0,
    children: /* @__PURE__ */ e(y, {
      color: a ? o.colors.grayscale.disabledDark : l,
      iconType: u,
      size: c,
      style: {
        display: "flex"
      }
    })
  });
}
export {
  v as IconType,
  T as default
};
