"use client";
import { useState as r, useEffect as d } from "react";
function s() {
  const [t, n] = r();
  return d(() => {
    if (typeof window > "u")
      return;
    const i = () => window.innerWidth - document.documentElement.clientWidth, e = () => n(i());
    return e(), window.addEventListener("resize", e), () => {
      window.removeEventListener("resize", e);
    };
  }, []), t;
}
export {
  s as default
};
