const o = {
  accordion: "accordion",
  accordionVisible: "accordion-visibleContent",
  accordionHidden: "accordion-hiddenContent",
  accordionExpandButton: "accordionExpandButton",
  accordionExpandTextButton: "accordion-expandTextButton"
};
export {
  o as dataTestsIds
};
