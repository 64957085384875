const e = "prześlij z urządzenia", t = "Przeciągnij tutaj lub ", l = "Wgraj CV z dysku", o = "input-fileFromDrive", i = "Wybierz typ dokumentu", n = "list motywacyjny", a = "inny dokument", s = "button-letterFileType", T = "button-otherFileType", r = "modal-fileTypeChoice";
export {
  i as fileTypeTitle,
  o as inputDataTest,
  e as labelText,
  l as labelTextCvFile,
  t as labelTextExtra,
  s as letterFileTypeDataTest,
  n as letterFileTypeLabel,
  r as modalDataTest,
  T as otherFileTypeDataTest,
  a as otherFileTypeLabel
};
