"use client";
import { jsxs as c, jsx as t } from "react/jsx-runtime";
import { useMemo as p } from "react";
import b from "../../../assets/styles/theme.js";
import O from "../../../hooks/use-dropdown.js";
import H from "../../atoms/Dropdown/Dropdown.js";
import j from "../../atoms/Label/Label.js";
import k from "../../atoms/Option/Option.js";
import z from "../../atoms/Checkbox/Checkbox.js";
import B from "../../atoms/Paragraph/Paragraph.js";
import h, { HelperTextType as f } from "../../atoms/HelperText/HelperText.js";
import { S as E } from "../../../Select.models-CABGx7YA.js";
import "../../../assets/icons/index.js";
import '../../../assets/Select.styles.css';/* empty css                                        */
import { StyledFieldWrapper as F, StyledSelectWrapper as K, StyledMultiSelect as L, StyledOptionField as V, StyledIcon as W } from "./MultiSelect.styles.js";
const $ = "wybierz", I = "text-errorMessage", P = "text-hintMessage", R = [];
function oe({
  id: m,
  name: r,
  label: y,
  values: e = R,
  required: s = !1,
  options: l,
  error: n = "",
  hint: a = "",
  onChange: S,
  isInlineField: u,
  size: g = E.M
}) {
  const { isOpen: i, handleOptionChange: x, handleClose: D, handleSelectByMouse: M, handleKeyDown: T } = O({
    onChange: S,
    closeOnChange: !1
  }), d = p(() => !!n, [n]), w = p(() => !!a, [a]), C = p(
    () => l.filter(({ value: o }) => e.includes(o)).map((o) => o.label).join(", ") || $,
    [l, e]
  );
  return /* @__PURE__ */ c(F, { "data-inline-field": u, "data-test": `field-${r}`, children: [
    /* @__PURE__ */ t(j, { hasError: d, htmlFor: m, isRequired: s, children: y }),
    /* @__PURE__ */ c(K, { children: [
      /* @__PURE__ */ t("input", { name: r, type: "hidden", value: (e == null ? void 0 : e.map(String)) || [] }),
      /* @__PURE__ */ t(
        L,
        {
          "data-size": g,
          "data-test": `multiselect-${r}`,
          hasError: d,
          id: m,
          isEmpty: !e || e.length === 0,
          isOpen: i,
          onChange: () => {
          },
          onKeyDown: T,
          onMouseDown: M,
          required: s,
          value: C
        }
      ),
      /* @__PURE__ */ t(H, { handleClose: D, isOpen: i, children: l.map((o) => /* @__PURE__ */ t(
        k,
        {
          isSelected: !1,
          onChange: () => x(o.value),
          value: o.value,
          children: /* @__PURE__ */ c(V, { children: [
            /* @__PURE__ */ t(z, { checked: e == null ? void 0 : e.includes(o.value) }),
            /* @__PURE__ */ t(B, { children: o.label })
          ] })
        },
        o.value
      )) }),
      /* @__PURE__ */ t(W, { color: b.colors.grayscale.dark, iconType: "CheveronDown", isOpen: i })
    ] }),
    d && /* @__PURE__ */ t(h, { dataTest: I, text: n, type: f.Error }),
    w && /* @__PURE__ */ t(h, { dataTest: P, text: a, type: f.Hint })
  ] });
}
export {
  oe as default
};
