const o = {
  alertBox: "initial",
  background: -1,
  checkboxInput: 10,
  dropdown: 9998,
  dropdownBackdrop: 15,
  formPanel: 1,
  gallery: 300,
  globalAlertBox: 9999,
  activeInput: 9999,
  multilineInputLabel: 1,
  option: 1,
  radioInput: 0,
  snackbar: 9999
};
export {
  o as default
};
