import { jsx as o } from "react/jsx-runtime";
import { s as n } from "../../../index-CARpW-rC.js";
import e from "../../../assets/styles/theme.js";
import '../../../assets/Label.css';var c = /* @__PURE__ */ ((t) => (t.Default = "default", t.Floating = "floating", t))(c || {});
const f = () => ({
  hasError: t
}) => t ? e.colors.complimentary.red : e.colors.grayscale.veryDark, d = () => ({
  hasError: t
}) => t ? e.colors.grayscale.dark : e.colors.primary.default, m = () => ({
  isRequired: t
}) => t ? "inline-block" : "none", p = /* @__PURE__ */ n("label")({
  name: "StyledLabel",
  class: "s19nntzt",
  propsAsIs: !1,
  vars: {
    "s19nntzt-0": [f()],
    "s19nntzt-1": [m()],
    "s19nntzt-2": [d()]
  }
});
function x({
  children: t,
  variant: a = "default",
  isRequired: l = !1,
  hasError: s = !1,
  htmlFor: r
}) {
  return /* @__PURE__ */ o(p, {
    "data-variant": a,
    hasError: s,
    htmlFor: r,
    isRequired: l,
    children: t
  });
}
export {
  c as LabelVariant,
  x as default
};
