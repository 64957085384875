"use client";
import { jsxs as c, jsx as t } from "react/jsx-runtime";
import { useMemo as S } from "react";
import B from "../../../assets/styles/theme.js";
import I from "../../../hooks/use-dropdown.js";
import y, { LabelVariant as j } from "../../atoms/Label/Label.js";
import z from "../../atoms/Dropdown/Dropdown.js";
import K from "../../atoms/Option/Option.js";
import D, { HelperTextType as x } from "../../atoms/HelperText/HelperText.js";
import { emptyOptionLabel as R, errorDataTest as W, hintDataTest as k } from "./Select.constants.js";
import { a as T, S as A } from "../../../Select.models-CABGx7YA.js";
import { StyledFieldWrapper as q, StyledSelectWrapper as G, StyledSelect as J, StyledOptionLabel as N, StyledIcon as P } from "./Select.styles.js";
function oe({
  ariaLabel: v,
  dataTest: p,
  disabled: w,
  name: a,
  id: O,
  label: m,
  value: l,
  required: o = !1,
  options: n,
  error: i = "",
  hint: d = "",
  placeholder: b,
  onChange: g,
  isInlineField: C,
  size: $ = A.M,
  variant: u = T.Default
}) {
  const { isOpen: s, handleOptionChange: f, handleClose: E, handleSelectByMouse: F, handleKeyDown: H } = I({
    onChange: g
  }), L = l === " " || l === null || l === void 0, h = u === T.Default, r = S(() => !!i, [i]), M = S(() => !!d, [d]), V = n.findIndex((e) => e.value === l);
  return /* @__PURE__ */ c(q, { "data-inline-field": C, "data-test": `field-${a}${p ? ` ${p}` : ""}`, children: [
    h && /* @__PURE__ */ t(y, { hasError: r, htmlFor: a, isRequired: o, children: m }),
    /* @__PURE__ */ c(G, { children: [
      /* @__PURE__ */ c(
        J,
        {
          "aria-label": v,
          "data-size": $,
          "data-test": `select-${a}`,
          "data-variant": u,
          disabled: w,
          hasError: r,
          id: O,
          isEmpty: L,
          isOpen: s,
          name: a,
          onChange: (e) => f(e.currentTarget.value),
          onKeyDown: H,
          onMouseDown: F,
          required: o,
          value: l !== null ? l : " ",
          children: [
            /* @__PURE__ */ t("option", { value: " ", children: b || R }),
            n.map((e) => /* @__PURE__ */ t("option", { value: e.value, children: e.label }, e.value))
          ]
        }
      ),
      !h && /* @__PURE__ */ t(y, { hasError: r, htmlFor: a, isRequired: o, variant: j.Floating, children: m }),
      /* @__PURE__ */ t(
        z,
        {
          dataTest: `dropdown-${a}`,
          handleClose: E,
          defaultSelectedOption: V,
          isOpen: s,
          isAutoSelect: !0,
          children: n.map((e) => /* @__PURE__ */ t(
            K,
            {
              onChange: () => f(e.value),
              value: e.value,
              isSelected: e.value === l,
              children: /* @__PURE__ */ t(N, { children: e.label })
            },
            e.value
          ))
        }
      ),
      /* @__PURE__ */ t(P, { color: B.colors.grayscale.dark, iconType: "CheveronDown", isOpen: s })
    ] }),
    r && /* @__PURE__ */ t(D, { dataTest: W, text: i, type: x.Error }),
    M && /* @__PURE__ */ t(D, { dataTest: k, text: d, type: x.Hint })
  ] });
}
export {
  oe as default
};
