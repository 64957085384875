"use client";
import { jsx as e, jsxs as a } from "react/jsx-runtime";
import { useState as l, useEffect as n } from "react";
import s from "../../atoms/ProgressBar/ProgressBar.js";
import { StyledFileLoader as d, StyledWrapper as m, StyledLabel as u } from "./FileLoader.styles.js";
import { fileLoaderDataTest as f, lodingText as c } from "./FileLoader.constants.js";
function S({ id: r, value: t }) {
  const [o, i] = l(0);
  return n(() => i(t), [t]), /* @__PURE__ */ e(d, { "data-test": f, children: /* @__PURE__ */ a(m, { children: [
    /* @__PURE__ */ e(s, { animationDuration: 500, height: 8, id: r, value: o }),
    /* @__PURE__ */ a(u, { htmlFor: r, children: [
      /* @__PURE__ */ e("strong", { children: `${o}%` }),
      " ",
      c
    ] })
  ] }) });
}
export {
  S as default
};
