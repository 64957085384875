import { s as r } from "../../../index-CARpW-rC.js";
import e from "../../../assets/styles/theme.js";
import l from "../../atoms/Icon/Icon.js";
import '../../../assets/MultiSelect.css';const y = /* @__PURE__ */ r("div")({
  name: "StyledFieldWrapper",
  class: "s1fk4ysl",
  propsAsIs: !1
}), o = () => ({
  isOpen: s,
  hasError: t
}) => s ? e.colors.primary.default : t ? e.colors.complimentary.red : e.colors.grayscale.dark, a = () => ({
  isOpen: s
}) => s ? e.colors.primary.default : "transparnet", p = () => ({
  isEmpty: s
}) => s ? e.colors.grayscale.dark : e.colors.grayscale.veryDark, S = /* @__PURE__ */ r("input")({
  name: "StyledMultiSelect",
  class: "s9m436x",
  propsAsIs: !1,
  vars: {
    "s9m436x-0": [o()],
    "s9m436x-1": [a()],
    "s9m436x-2": [p()]
  }
}), f = /* @__PURE__ */ r("div")({
  name: "StyledSelectWrapper",
  class: "suoc6iu",
  propsAsIs: !1
}), u = /* @__PURE__ */ r("div")({
  name: "StyledOptionField",
  class: "s1tkbcdc",
  propsAsIs: !1
}), c = () => l, n = () => ({
  isOpen: s
}) => s ? "rotate(180deg)" : "none", x = /* @__PURE__ */ r(c())({
  name: "StyledIcon",
  class: "s8w1j2p",
  propsAsIs: !0,
  vars: {
    "s8w1j2p-0": [n()]
  }
});
export {
  y as StyledFieldWrapper,
  x as StyledIcon,
  S as StyledMultiSelect,
  u as StyledOptionField,
  f as StyledSelectWrapper
};
