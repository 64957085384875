import { s as o } from "../../../index-CARpW-rC.js";
import a from "../../../assets/styles/theme.js";
import '../../../assets/Badge.css';const e = () => ({
  bgColor: s
}) => s ?? a.colors.primary.default, l = () => ({
  textColor: s
}) => s ?? a.colors.grayscale.white, r = () => ({
  textBold: s
}) => s ? 600 : 400, d = /* @__PURE__ */ o("span")({
  name: "StyledBadge",
  class: "s1vway6m",
  propsAsIs: !1,
  vars: {
    "s1vway6m-0": [e()],
    "s1vway6m-1": [l()],
    "s1vway6m-2": [r()]
  }
});
export {
  d as StyledBadge
};
