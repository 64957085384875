"use client";
import { jsxs as b, jsx as e } from "react/jsx-runtime";
import { useMemo as d } from "react";
import y from "../../atoms/Label/Label.js";
import n, { HelperTextType as p } from "../../atoms/HelperText/HelperText.js";
import { StyledFieldWrapper as g, StyledTextarea as H } from "./MultilineInput.styles.js";
const I = -1, j = 8, B = 500, D = "text-errorMessage", E = "text-hintMessage";
function q({
  dataTest: l,
  maxLength: f = B,
  name: t,
  label: x,
  value: c,
  readonly: a = !1,
  required: u = !1,
  error: r = "",
  isHidden: i = !1,
  hint: s = "",
  rows: m = j,
  onBlur: h,
  onChange: T,
  isInlineField: M
}) {
  const o = d(() => !!r, [r]), $ = d(() => !!s, [s]);
  return /* @__PURE__ */ b(
    g,
    {
      collapsed: i,
      "data-inline-field": M,
      "data-test": `field-${t}${i ? "-hidden" : ""}${l ? ` ${l}` : ""}`,
      readonly: a,
      children: [
        /* @__PURE__ */ e(y, { hasError: o, htmlFor: t, isRequired: u, children: x }),
        /* @__PURE__ */ e(
          H,
          {
            "data-test": `textarea-${t}`,
            hasError: o,
            id: t,
            maxLength: f,
            name: t,
            onBlur: h,
            onChange: T,
            placeholder: " ",
            readOnly: a,
            rows: m,
            tabIndex: a ? I : 0,
            value: c
          }
        ),
        o && /* @__PURE__ */ e(n, { dataTest: D, text: r, type: p.Error }),
        $ && /* @__PURE__ */ e(n, { dataTest: E, text: s, type: p.Hint })
      ]
    }
  );
}
export {
  q as default
};
