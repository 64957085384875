import { jsx as i } from "react/jsx-runtime";
import l from "../../../assets/icons/index.js";
const s = 24;
function h({
  iconType: t,
  className: r,
  color: c = "currentColor",
  size: o = s,
  onClick: e,
  style: a
}) {
  const n = l.find((u) => u.iconName === t), d = `div-icon${t}`;
  return n ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    /* @__PURE__ */ i("picture", { className: r, "data-test": d, onClick: e, style: a, children: /* @__PURE__ */ i(n, { role: "img", color: c, height: o, width: o, "aria-hidden": "true" }) })
  ) : null;
}
export {
  h as default
};
