"use client";
import { jsxs as h, jsx as o } from "react/jsx-runtime";
import { useMemo as d } from "react";
import u from "../../atoms/Checkbox/Checkbox.js";
import C from "../../atoms/RichLabel/RichLabel.js";
import "../../atoms/RichLabel/RichLabel.styles.js";
import T, { HelperTextType as b } from "../../atoms/HelperText/HelperText.js";
import { StyledFieldWrapper as k } from "./CheckboxField.styles.js";
const y = "text-errorMessage";
function B({
  id: e,
  name: a,
  checked: m,
  indeterminate: p,
  label: s,
  labelmaxCharacters: i,
  labelExtraContent: n,
  required: l,
  value: x,
  dataTest: c,
  onChange: f,
  error: t = ""
}) {
  const r = d(() => !!t, [t]);
  return /* @__PURE__ */ h(k, { "data-has_error": r, "data-test": c ?? `field-${e}`, "data-checkbox-wrapper": !0, children: [
    /* @__PURE__ */ o(
      u,
      {
        checked: m,
        id: e,
        indeterminate: p,
        name: a,
        onChange: f,
        required: l,
        value: x,
        hasError: r
      }
    ),
    /* @__PURE__ */ o(C, { extraContent: n, htmlFor: e, mainContent: s, maxCharacters: i }),
    r && /* @__PURE__ */ o(T, { dataTest: y, text: t, type: b.Error })
  ] });
}
export {
  B as default
};
