import { s as e } from "../../../index-CARpW-rC.js";
import t from "../../../assets/styles/theme.js";
import '../../../assets/ChooseFileFromDriveInput.css';const a = () => ({
  isDragOver: s
}) => s ? "2px" : "1px", n = /* @__PURE__ */ e("div")({
  name: "StyledWrapper",
  class: "s1m9vjbk",
  propsAsIs: !1,
  vars: {
    "s1m9vjbk-0": [a()]
  }
}), l = () => ({
  isDragOver: s
}) => s ? "var(--stripes-background)" : t.colors.grayscale.white, o = () => ({
  isDragOver: s
}) => s ? `3px solid ${t.colors.grayscale.white}` : "4px solid transparent", c = /* @__PURE__ */ e("label")({
  name: "StyledLabel",
  class: "sq6fnmx",
  propsAsIs: !1,
  vars: {
    "sq6fnmx-0": [l()],
    "sq6fnmx-1": [o()]
  }
}), d = /* @__PURE__ */ e("input")({
  name: "StyledInput",
  class: "si95ssc",
  propsAsIs: !1
}), m = /* @__PURE__ */ e("div")({
  name: "StyledButtonsBox",
  class: "sn3syqr",
  propsAsIs: !1
});
export {
  m as StyledButtonsBox,
  d as StyledInput,
  c as StyledLabel,
  n as StyledWrapper
};
