import { jsxs as i, Fragment as t, jsx as e } from "react/jsx-runtime";
import { moreButtonText as l, lessButtonText as m } from "./RichLabel.constants.js";
import { StyledInlineButton as u } from "./RichLabel.styles.js";
function f({ isExtraContentHidden: n, withSpace: o, handleOnMoreButtonClick: r }) {
  return /* @__PURE__ */ i(t, { children: [
    o && /* @__PURE__ */ e(t, { children: " " }),
    /* @__PURE__ */ e(u, { onClick: r, type: "button", children: n ? l : m })
  ] });
}
export {
  f as default
};
