"use client";
import { jsxs as p, jsx as o } from "react/jsx-runtime";
import { useEffect as z, Fragment as E } from "react";
import G from "../../atoms/Dropdown/Dropdown.js";
import H from "../../atoms/Option/Option.js";
import { a as T, b as W } from "../../../Input-DJu9GhhH.js";
import "../../atoms/Input/Input.styles.js";
import k from "../TextInput/TextInput.js";
import B from "../../../hooks/use-autocomplete.js";
import { StyledAutocompleteWrapper as J, StyledGroupHeadline as K, StyledOptionLabel as N } from "./Autocomplete.styles.js";
function ne({
  debounceTime: d,
  dropdownHeadline: m,
  emptyDropdownMessage: c,
  error: a = "",
  hint: l = "",
  label: f,
  name: h,
  value: g,
  options: O,
  placeholder: S = "",
  hasOptionGroups: i = !1,
  isAutoclean: v = !1,
  isRequired: x = !1,
  isCustomDataType: C = !1,
  inputSize: I = T.M,
  variant: y = W.Default,
  onSearch: $,
  onChange: b,
  onBlur: A,
  isInlineField: F
}) {
  const {
    areOptionsVisible: t,
    focusedOptionIndex: s,
    handleInputChange: V,
    handleClose: j,
    handleFocus: q,
    handleOptionChange: D,
    inputValue: r,
    isLoading: L,
    suggestions: M,
    suggestionGroups: w
  } = B({
    debounceTime: d,
    hasOptionGroups: i,
    onSearch: $,
    onChange: b,
    value: g,
    options: O,
    isAutoclean: v,
    isCustomDataType: C
  });
  z(() => {
    var e;
    (e = document.querySelector(`#suggestion-${s}`)) == null || e.focus();
  }, [s]);
  const u = (e) => e.map((n) => /* @__PURE__ */ o(
    H,
    {
      id: `suggestion-${n.value}`,
      isSelected: n.name === r,
      onChange: () => D(n),
      value: n.value,
      children: /* @__PURE__ */ o(N, { children: n.name })
    },
    `${n.name}-${n.value}`
  ));
  return /* @__PURE__ */ p(J, { "data-inline-field": F, children: [
    /* @__PURE__ */ o(
      k,
      {
        error: !t && a ? a : "",
        hint: !t && l ? l : "",
        inputSize: I,
        isOpen: t,
        onBlur: A,
        onChange: V,
        onFocus: q,
        required: x,
        variant: y,
        label: f,
        name: h,
        placeholder: S,
        value: r
      }
    ),
    /* @__PURE__ */ o(
      G,
      {
        defaultMessage: c,
        handleClose: j,
        headline: m,
        isLoading: L,
        isOpen: t,
        children: i ? w.map((e) => /* @__PURE__ */ p(E, { children: [
          /* @__PURE__ */ o(K, { children: e.name }),
          u(e.items)
        ] }, e.name)) : u(M)
      }
    )
  ] });
}
export {
  ne as default
};
