import { s as e } from "../../../index-CARpW-rC.js";
import '../../../assets/Popup.css';const l = /* @__PURE__ */ e("div")({
  name: "StyledPopupOverlay",
  class: "s1sbubk0",
  propsAsIs: !1
}), p = () => ({
  wide: s
}) => s ? "740px" : "588px", o = () => ({
  flex: s
}) => s ? "auto" : "100%", a = () => ({
  bottomPositionOnMobile: s
}) => s, d = /* @__PURE__ */ e("div")({
  name: "StyledPopupBox",
  class: "s17x74eb",
  propsAsIs: !1,
  vars: {
    "s17x74eb-0": [p()],
    "s17x74eb-1": [o()],
    "s17x74eb-2": [a()]
  }
}), r = /* @__PURE__ */ e("div")({
  name: "StyledPopupHeader",
  class: "skoyo9d",
  propsAsIs: !1
}), x = /* @__PURE__ */ e("h3")({
  name: "StyledPopupHeaderText",
  class: "sxj5kru",
  propsAsIs: !1
});
export {
  d as StyledPopupBox,
  r as StyledPopupHeader,
  x as StyledPopupHeaderText,
  l as StyledPopupOverlay
};
