import { s } from "../../../index-CARpW-rC.js";
import a from "../Loader/Loader.js";
import '../../../assets/Dropdown.css';const d = () => ({
  isOpen: e
}) => e ? "visible" : "hidden", t = () => ({
  isOpen: e
}) => e ? 1 : 0, n = /* @__PURE__ */ s("ul")({
  name: "StyledDropdown",
  class: "s104agmi",
  propsAsIs: !1,
  vars: {
    "s104agmi-0": [d()],
    "s104agmi-1": [t()]
  }
}), r = /* @__PURE__ */ s("div")({
  name: "StyledHeader",
  class: "snt5gas",
  propsAsIs: !1
}), c = /* @__PURE__ */ s("h2")({
  name: "StyledHeadline",
  class: "stm3uqd",
  propsAsIs: !1
}), l = () => a, m = /* @__PURE__ */ s(l())({
  name: "StyledLoader",
  class: "s1dwp1iu",
  propsAsIs: !0
}), i = /* @__PURE__ */ s("p")({
  name: "StyledMessage",
  class: "s1q3qs3a",
  propsAsIs: !1
});
export {
  n as StyledDropdown,
  r as StyledHeader,
  c as StyledHeadline,
  m as StyledLoader,
  i as StyledMessage
};
