import { s } from "../../../index-CARpW-rC.js";
import '../../../assets/FileLoader.css';const l = /* @__PURE__ */ s("div")({
  name: "StyledFileLoader",
  class: "stnlkww",
  propsAsIs: !1
}), a = /* @__PURE__ */ s("div")({
  name: "StyledWrapper",
  class: "s73u4v6",
  propsAsIs: !1
}), t = /* @__PURE__ */ s("label")({
  name: "StyledLabel",
  class: "s1or636l",
  propsAsIs: !1
});
export {
  l as StyledFileLoader,
  t as StyledLabel,
  a as StyledWrapper
};
