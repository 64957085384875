import { s } from "../../../index-CARpW-rC.js";
import '../../../assets/FileCard.css';const e = /* @__PURE__ */ s("div")({
  name: "StyledWrapper",
  class: "ssk922e",
  propsAsIs: !1
}), a = /* @__PURE__ */ s("div")({
  name: "StyledContent",
  class: "sru1iqm",
  propsAsIs: !1
}), l = /* @__PURE__ */ s("strong")({
  name: "StyledName",
  class: "s1i1hmr7",
  propsAsIs: !1
}), n = /* @__PURE__ */ s("time")({
  name: "StyledDate",
  class: "sx6hih7",
  propsAsIs: !1
}), o = /* @__PURE__ */ s("div")({
  name: "StyledButtons",
  class: "s1m038vx",
  propsAsIs: !1
}), d = /* @__PURE__ */ s("div")({
  name: "StyledIconButtons",
  class: "s1nscsgi",
  propsAsIs: !1
});
export {
  o as StyledButtons,
  a as StyledContent,
  n as StyledDate,
  d as StyledIconButtons,
  l as StyledName,
  e as StyledWrapper
};
