"use client";
import { jsxs as s, jsx as e } from "react/jsx-runtime";
import { memo as j, useRef as w, useMemo as m } from "react";
import l from "../../../helpers/handleClearDate.js";
import { I as B, b as d, a as C } from "../../../Input-DJu9GhhH.js";
import { StyledInputWrapper as L } from "../../atoms/Input/Input.styles.js";
import W, { LabelVariant as f } from "../../atoms/Label/Label.js";
import $ from "../../atoms/FieldWrapper/FieldWrapper.js";
import "../../atoms/FieldWrapper/FieldWrapper.styles.js";
import c, { HelperTextType as x } from "../../atoms/HelperText/HelperText.js";
import { errorDataTest as q, hintDataTest as O, defaultMinDate as S, defaultMaxDate as k, inputTimeoutDelay as z } from "./DateInput.constants.js";
const A = (t) => window.setTimeout(() => t.target.defaultValue = "", z), G = (t) => t.nativeEvent.target.defaultValue = "";
function J({
  error: t = "",
  hint: a = "",
  name: o,
  minDate: D = S,
  maxDate: h = k,
  id: y,
  label: I,
  readOnly: u = !1,
  inputSize: i = C.M,
  variant: p = d.Default,
  required: T,
  value: V,
  onBlur: F,
  onChange: H,
  isInlineField: M
}) {
  const b = w(null), n = m(() => !!t, [t]), g = m(() => !!a, [a]), v = l(V), E = l(D), R = l(h);
  return /* @__PURE__ */ s(
    $,
    {
      "data-inline-field": M,
      "data-test": `field-${o}`,
      inputSize: i,
      readOnly: u,
      children: [
        /* @__PURE__ */ s(L, { children: [
          /* @__PURE__ */ e(
            B,
            {
              ref: b,
              defaultValue: "",
              max: R,
              min: E,
              onFocus: (r) => G(r),
              onInput: (r) => A(r),
              placeholder: "dd/mm/yyyy",
              pattern: "\\d{2}/\\d{2}/\\d{4}",
              type: "date",
              value: v,
              id: y,
              hasError: n,
              name: o,
              onBlur: F,
              onChange: H,
              readOnly: u,
              inputSize: i,
              variant: p
            }
          ),
          /* @__PURE__ */ e(
            W,
            {
              hasError: n,
              htmlFor: o,
              isRequired: T,
              variant: p === d.Compact ? f.Floating : f.Default,
              children: I
            }
          )
        ] }),
        n && /* @__PURE__ */ e(c, { dataTest: q, text: t, type: x.Error }),
        g && /* @__PURE__ */ e(c, { dataTest: O, text: a, type: x.Hint })
      ]
    }
  );
}
const et = j(J);
export {
  et as default
};
