"use client";
import { jsxs as r, jsx as a } from "react/jsx-runtime";
import { F as l, a as C } from "../../../FileCardButtons-nSyVUOAo.js";
import { StyledWrapper as F, StyledContent as y, StyledName as h } from "./FileCard.styles.js";
import x from "./FileCardExtraInfo.js";
const D = "button-fileName";
function B({
  file: e,
  dataTest: d,
  variant: t = l.Default,
  canDownload: n = !1,
  canDisplay: o = !1,
  withExtraInfo: i = !1,
  displayFile: s,
  detachFile: f,
  removeFile: m,
  selectFile: c,
  isDownloading: u = !1,
  handleDownload: p
}) {
  return /* @__PURE__ */ r(
    F,
    {
      "data-test": d,
      "data-variant": t,
      onClick: t === l.Select ? c : void 0,
      children: [
        /* @__PURE__ */ r(y, { "data-variant": t, children: [
          i ? /* @__PURE__ */ a(x, { createDate: e.createDate, typeId: e.typeId }) : null,
          /* @__PURE__ */ a(h, { "data-test": D, children: `${e.fileName}.${e.fileFormat}` })
        ] }),
        /* @__PURE__ */ a(
          C,
          {
            handleRemove: m,
            variant: t,
            handleDownload: p,
            isDownloading: u,
            displayFile: s,
            canDownload: n,
            canDisplay: o,
            detachFile: f
          }
        )
      ]
    }
  );
}
export {
  B as default
};
