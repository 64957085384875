import { jsx as r, jsxs as c } from "react/jsx-runtime";
import { useState as d, useRef as s, useCallback as h, useEffect as m } from "react";
import p from "../../atoms/Icon/Icon.js";
import f from "../../../assets/styles/theme.js";
import { StyledRules as g } from "./PasswordInput.styles.js";
import { conditionCheckedLabel as u, conditionUncheckedLabel as b } from "./PasswordInput.constants.js";
function v({ rules: t }) {
  const [l, a] = d("0px"), o = s(null), i = h(() => {
    var e;
    const n = `${(e = o == null ? void 0 : o.current) == null ? void 0 : e.scrollHeight}px`;
    a(n);
  }, []);
  return m(() => (i(), window.addEventListener("resize", i), window.removeEventListener("resize", i)), [i, t]), (t == null ? void 0 : t.length) > 0 ? /* @__PURE__ */ r(g, { ref: o, maxHeight: l, children: /* @__PURE__ */ r("ul", { children: t.map(({ text: n, checked: e }) => /* @__PURE__ */ c("li", { "aria-label": e ? u : b, "data-checked": e, children: [
    /* @__PURE__ */ r(p, { color: f.colors.grayscale.disabledDark, iconType: e ? "FilledCheck" : "Check", size: 20 }),
    n
  ] }, n)) }) }) : null;
}
export {
  v as default
};
