"use client";
import { useState as i, useEffect as o } from "react";
const c = {
  config: { attributes: !0, childList: !0, subtree: !0 }
};
function f({ targetEl: t, callBack: u, options: s = c }) {
  const [e, n] = i(null);
  o(() => {
    const r = new MutationObserver(u);
    n(r);
  }, [u, s, n]), o(() => {
    if (e && t) {
      const { config: r } = s;
      e.observe(t, r);
    }
    return () => {
      e && e.disconnect();
    };
  }, [e, t, s]);
}
export {
  f as default
};
