"use client";
import { jsxs as d, Fragment as n, jsx as g } from "react/jsx-runtime";
import a from "../../../assets/styles/theme.js";
import { B as s } from "../../../Badge-CkhiT_Az.js";
import { FileType as e } from "../../../@types/types.js";
import { StyledDate as m } from "./FileCard.styles.js";
const b = "dodano", c = "text-fileDate", p = [
  { badgeType: e.CV, badgeText: "CV", badgeColor: a.colors.primary.default },
  { badgeType: e.Letter, badgeText: "List", badgeColor: a.colors.primary.light },
  { badgeType: e.Other, badgeText: "Inne", badgeColor: a.colors.primary.light }
];
function C({ createDate: i, typeId: o }) {
  const t = p.find(({ badgeType: l }) => l === o), r = new Date(i).toLocaleDateString("pl", {
    day: "2-digit",
    month: "long",
    year: "numeric"
  });
  return /* @__PURE__ */ d(n, { children: [
    t ? (
      // eslint-disable-next-line security/detect-object-injection
      /* @__PURE__ */ g(s, { bgColor: t.badgeColor, dataTest: `text-fileType-${e[o]}`, children: t.badgeText })
    ) : null,
    /* @__PURE__ */ d(m, { "data-test": c, dateTime: r, children: [
      b,
      " ",
      r
    ] })
  ] });
}
export {
  C as default
};
