import { s as e } from "../../../index-CARpW-rC.js";
import '../../../assets/FieldWrapper.css';const a = () => ({
  readOnly: s
}) => s ? "none" : "auto", r = /* @__PURE__ */ e("div")({
  name: "StyledFieldWrapper",
  class: "s127c6is",
  propsAsIs: !1,
  vars: {
    "s127c6is-0": [a()]
  }
});
export {
  r as StyledFieldWrapper
};
