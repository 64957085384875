import { jsx as g } from "react/jsx-runtime";
import { forwardRef as q, useCallback as e } from "react";
import { StyledInput as z } from "./components/atoms/Input/Input.styles.js";
var u = /* @__PURE__ */ ((t) => (t.Default = "default", t.Compact = "compact", t))(u || {}), m = /* @__PURE__ */ ((t) => (t.M = "M", t.L = "L", t))(m || {});
const A = -1, B = q(
  ({
    name: t,
    id: c,
    isOpen: l = !1,
    placeholder: i = " ",
    readOnly: s = !1,
    hasError: p,
    type: x = "text",
    variant: b = u.Default,
    inputSize: I = m.M,
    pattern: h,
    onBlur: v,
    onChange: M,
    onFocus: r,
    onInvalid: k,
    onKeyDown: d,
    onKeyUp: f,
    onInput: C,
    value: L,
    ...j
  }, N) => {
    const R = e(
      (a) => {
        if (l) {
          a.preventDefault();
          return;
        }
        r == null || r(a);
      },
      [l, r]
    ), T = e(
      (a) => {
        d == null || d(a);
      },
      [d]
    ), $ = e(
      (a) => {
        f == null || f(a);
      },
      [f]
    );
    return /* @__PURE__ */ g(
      z,
      {
        "data-size": I,
        "data-test": `input-${t}`,
        "data-variant": b,
        id: c ?? t,
        onFocus: R,
        onInvalid: k,
        onKeyDown: T,
        onKeyUp: $,
        tabIndex: s ? A : 0,
        value: L,
        pattern: h,
        ref: N,
        hasError: p,
        isOpen: l,
        name: t,
        onBlur: v,
        onChange: M,
        placeholder: i,
        readOnly: s,
        type: x,
        onInput: C,
        ...j
      }
    );
  }
);
B.displayName = "Input";
export {
  B as I,
  m as a,
  u as b
};
