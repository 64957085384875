"use client";
import { jsxs as e, jsx as t } from "react/jsx-runtime";
import { useState as c } from "react";
import { s as o } from "../../../index-CARpW-rC.js";
import '../../../assets/Collapse.css';const r = /* @__PURE__ */ o("div")({
  name: "StyledCollapse",
  class: "s15oyqd9",
  propsAsIs: !1
}), n = /* @__PURE__ */ o("button")({
  name: "StyledInlineButton",
  class: "s83kjta",
  propsAsIs: !1
}), u = "więcej", f = "mniej";
function h({
  title: a,
  content: d,
  openText: i = u,
  hideText: p = f
}) {
  const [s, l] = c(!1);
  return /* @__PURE__ */ e(r, {
    children: [/* @__PURE__ */ e("p", {
      children: [/* @__PURE__ */ t("span", {
        children: a
      }), "  ", /* @__PURE__ */ t(n, {
        disabled: s,
        onClick: () => l(!0),
        type: "button",
        children: i
      })]
    }), s && /* @__PURE__ */ e("p", {
      children: [d, "  ", /* @__PURE__ */ t(n, {
        onClick: () => l(!1),
        type: "button",
        children: p
      })]
    })]
  });
}
export {
  h as default
};
