"use client";
import { useState as c, useCallback as o } from "react";
function a() {
  const [p, e] = c(!1), s = o(() => {
    e(!0);
  }, []), t = o(() => {
    e(!1);
  }, []), u = o(() => {
    e((n) => !n);
  }, []);
  return {
    isPopupOpen: p,
    openPopup: s,
    closePopup: t,
    togglePopup: u
  };
}
export {
  a as default
};
