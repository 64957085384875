import { s as r } from "../../../index-CARpW-rC.js";
import s from "../../../assets/styles/theme.js";
import '../../../assets/Input.css';const e = () => ({
  hasError: t
}) => t ? s.colors.complimentary.red : s.colors.grayscale.dark, o = () => ({
  hasError: t
}) => t ? s.colors.complimentary.red : s.colors.primary.default, l = /* @__PURE__ */ r("input")({
  name: "StyledInput",
  class: "s6f6t4j",
  propsAsIs: !1,
  vars: {
    "s6f6t4j-0": [e()],
    "s6f6t4j-1": [o()]
  }
}), c = /* @__PURE__ */ r("div")({
  name: "StyledInputWrapper",
  class: "s1ogcw6o",
  propsAsIs: !1
});
export {
  l as StyledInput,
  c as StyledInputWrapper
};
