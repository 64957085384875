import { s as r } from "../../../index-CARpW-rC.js";
import '../../../assets/ProgressBar.css';const e = () => ({
  duration: s
}) => `${s}ms`, c = /* @__PURE__ */ r("div")({
  name: "StyledProgressWrapper",
  class: "s12ptise",
  propsAsIs: !1,
  vars: {
    "s12ptise-0": [e()]
  }
}), p = () => ({
  height: s
}) => `${s}px`, a = () => ({
  color: s
}) => s, x = /* @__PURE__ */ r("progress")({
  name: "StyledProgress",
  class: "s41pxr0",
  propsAsIs: !1,
  vars: {
    "s41pxr0-0": [p()],
    "s41pxr0-1": [a()]
  }
}), o = () => ({
  width: s
}) => `${s}%`, t = () => ({
  color: s
}) => s, n = /* @__PURE__ */ r("span")({
  name: "StyledProgressBarFallback",
  class: "sj5s5x5",
  propsAsIs: !1,
  vars: {
    "sj5s5x5-0": [o()],
    "sj5s5x5-1": [t()]
  }
});
export {
  x as StyledProgress,
  n as StyledProgressBarFallback,
  c as StyledProgressWrapper
};
