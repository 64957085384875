"use client";
import { jsxs as B, jsx as m } from "react/jsx-runtime";
import { useState as s, useEffect as d } from "react";
import l from "./components/atoms/Button/Button.js";
import { b as T, a as S } from "./ButtonContent-DUgA__eh.js";
import "./assets/icons/index.js";
import "./components/atoms/Button/Button.styles.js";
import { buttonLabelDefault as x } from "./components/atoms/Snackbar/Snackbar.constants.js";
import { StyledSnackbar as L, StyledText as g } from "./components/atoms/Snackbar/Snackbar.styles.js";
var a = /* @__PURE__ */ ((t) => (t.BottomLeft = "BottomLeft", t.BottomCenter = "BottomCenter", t.BottomRight = "BottomRight", t.TopLeft = "TopLeft", t.TopCenter = "TopCenter", t.TopRight = "TopRight", t))(a || {});
function i({
  buttonLabel: t = x,
  dataTest: p,
  isVisible: e,
  message: f,
  onClickAction: o,
  position: n = a.BottomCenter
}) {
  const [u, r] = s(e), h = () => {
    o == null || o(), r(!1);
  };
  return d(() => {
    r(e);
  }, [e]), u ? /* @__PURE__ */ B(L, { "data-position": n, "data-test": p, children: [
    /* @__PURE__ */ m(g, { children: f }),
    /* @__PURE__ */ m(l, { onClick: h, size: T.S, variant: S.Text, children: t })
  ] }) : null;
}
export {
  i as S,
  a
};
