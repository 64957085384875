import { s as t } from "../../../index-CARpW-rC.js";
import o from "../../../assets/styles/theme.js";
import '../../../assets/Option.css';const r = () => ({
  isSelected: s
}) => s ? o.colors.primary.highlight : "transparent", a = /* @__PURE__ */ t("li")({
  name: "StyledOption",
  class: "s4fj5oy",
  propsAsIs: !1,
  vars: {
    "s4fj5oy-0": [r()]
  }
});
export {
  a as StyledOption
};
