import { s as e } from "../../../index-CARpW-rC.js";
import '../../../assets/Gallery.css';const a = 15, t = () => ({
  isDisplayed: s
}) => s ? "visible" : "hidden", l = () => ({
  isDisplayed: s
}) => s ? 1 : 0, d = /* @__PURE__ */ e("div")({
  name: "StyledGallery",
  class: "s1dqm5da",
  propsAsIs: !1,
  vars: {
    "s1dqm5da-0": [t()],
    "s1dqm5da-1": [l()]
  }
}), n = /* @__PURE__ */ e("button")({
  name: "StyledCloseActionButton",
  class: "s1r07wqs",
  propsAsIs: !1
}), c = /* @__PURE__ */ e("img")({
  name: "StyledImage",
  class: "srik8y",
  propsAsIs: !1
});
export {
  n as StyledCloseActionButton,
  d as StyledGallery,
  c as StyledImage,
  a as iconSize
};
