const u = "||";
function g({ file: s, filePolicy: t }) {
  if (!s || !t)
    return;
  const { fileFormats: n, fileSizeInBytesLimit: o, nameLengthLimit: i } = t, r = s == null ? void 0 : s.name.slice(0, s == null ? void 0 : s.name.lastIndexOf(".")), m = s == null ? void 0 : s.name.slice(s.name.lastIndexOf(".") + 1), e = [], a = {
    fileFormatMessage: `Zły rodzaj pliku, dopuszczalne typy: ${n.join(", ")}.`,
    fileNameLengthMessage: `Nazwa pliku jest za długa. Maks. ${i} znaków.`,
    fileSizeMessage: `Za duży plik, maksymalny rozmiar pliku to ${Math.round(o / 1024)} kB.`
  };
  return (s == null ? void 0 : s.size) > o && e.push(a.fileSizeMessage), r.length > i && e.push(a.fileNameLengthMessage), n.includes(m) || e.push(a.fileFormatMessage), e.join(u);
}
export {
  g as default
};
