import { s } from "../../../index-CARpW-rC.js";
import '../../../assets/Snackbar.css';const e = /* @__PURE__ */ s("div")({
  name: "StyledSnackbar",
  class: "s1irghv1",
  propsAsIs: !1
}), t = /* @__PURE__ */ s("span")({
  name: "StyledText",
  class: "sn886w0",
  propsAsIs: !1
});
export {
  e as StyledSnackbar,
  t as StyledText
};
