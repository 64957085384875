import { useMemo as N, useState as i, useCallback as h, useEffect as P } from "react";
import Q from "./use-debounce.js";
import R from "../helpers/get-error-message.js";
function Y({
  debounceTime: O,
  hasOptionGroups: k,
  onSearch: S,
  onChange: g,
  value: r,
  options: l,
  isAutoclean: y,
  isCustomDataType: a
}) {
  const d = N(() => r ? String(a ? Array.isArray(r) ? r[0] : "" : r) : "", [a, r]), [n, c] = i(l || []), [L, x] = i([]), [A, V] = i(!1), [C, w] = i(!1), [E, u] = i(!1), [F, G] = i(null), [b, M] = i(!0), p = Q(d, O), m = l && l.length > 0, I = !m && !A, j = (t) => {
    const e = t.reduce(
      (s, f) => {
        const o = f.type || "";
        return s[o] || (s[o] = []), s[o].push(f), s;
      },
      {}
    );
    x(
      Object.keys(e).map((s) => ({
        name: s,
        items: e[s]
      }))
    );
  }, B = h(
    async (t) => {
      if (!t || !S) {
        u(!1), c([]);
        return;
      }
      w(!0), u(!0);
      try {
        const e = await S(t);
        c(e || []), k && j(e || []);
      } catch (e) {
        throw new Error(R(e));
      }
      w(!1);
    },
    [p]
  ), q = () => {
    const t = !!(n != null && n.find(({ name: e }) => e === d));
    y && !t && g(a ? [] : ""), u(!1);
  }, z = h(
    (t) => {
      const { value: e } = t.target, s = n.find(({ name: o }) => o === e) || { name: e, value: "" }, f = [s.name, s.value];
      if (g(a ? f : e), V(!1), b && M(!1), m && u(!0), m && e.length > 0) {
        const o = l.filter(
          (K) => K.name.toLowerCase().includes(e.toLowerCase())
        );
        c(o);
      }
      e.length === 0 && c(l || []);
    },
    [r, n]
  ), H = (t) => {
    g(a ? [t.name, t.value] : t.name), u(!1), V(!0);
  }, J = h(() => {
    n.length > 0 && (u(!0), G(null));
  }, [n]);
  return P(() => {
    I && !b && B(p);
  }, [p, I]), {
    areOptionsVisible: E,
    focusedOptionIndex: F,
    handleInputChange: z,
    handleClose: q,
    handleFocus: J,
    handleOptionChange: H,
    inputValue: d,
    isLoading: C,
    suggestions: n,
    suggestionGroups: L
  };
}
export {
  Y as default
};
