"use client";
import { jsxs as c, jsx as e } from "react/jsx-runtime";
import n from "../../../assets/styles/theme.js";
import s from "../../atoms/Icon/Icon.js";
import { StyledGallery as a, StyledCloseActionButton as m, iconSize as y, StyledImage as d } from "./Gallery.styles.js";
function S({ dataTest: t, isDisplayed: o, imageUrl: r, imageAlt: l, onClose: i }) {
  return /* @__PURE__ */ c(a, { "data-test": t, isDisplayed: o, children: [
    /* @__PURE__ */ e(m, { onClick: i, type: "button", children: /* @__PURE__ */ e(s, { color: n.colors.grayscale.white, iconType: "Close", size: y }) }),
    /* @__PURE__ */ e(d, { alt: l, src: r })
  ] });
}
export {
  S as default
};
