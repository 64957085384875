import { useState as p, useCallback as l } from "react";
function i({ onChange: a, closeOnChange: n = !0 }) {
  const [o, s] = p(!1), r = (e) => {
    e.preventDefault(), s((t) => !t);
  }, c = (e) => {
    switch (e.key) {
      case " ":
      // Space
      case "Enter":
        e.preventDefault(), s((t) => !t);
        break;
      case "Escape":
        e.preventDefault(), s(!1);
        break;
    }
  }, u = l(() => s(!1), [s]), f = l(
    (e) => {
      n && s(!1), a(e);
    },
    [a, n]
  );
  return {
    isOpen: o,
    handleSelectByMouse: r,
    handleKeyDown: c,
    handleClose: u,
    handleOptionChange: f
  };
}
export {
  i as default
};
