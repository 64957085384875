"use client";
import { jsx as e, jsxs as i } from "react/jsx-runtime";
import o from "./components/atoms/IconButton/IconButton.js";
import c from "./components/atoms/Button/Button.js";
import { b as s, a as l } from "./ButtonContent-DUgA__eh.js";
import "./assets/icons/index.js";
import "./components/atoms/Button/Button.styles.js";
import { StyledIconButtons as a, StyledButtons as B } from "./components/molecules/FileCard/FileCard.styles.js";
var z = /* @__PURE__ */ ((t) => (t[t.Default = 0] = "Default", t[t.Select = 1] = "Select", t[t.Remove = 2] = "Remove", t[t.Compact = 3] = "Compact", t))(z || {});
const { Select: D, Remove: k, Compact: y } = z, x = "wybierz plik", v = "zmień lub odrzuć plik", C = "zmień lub odrzuć zdjęcie", p = "pobierz plik", w = "zobacz zdjęcie", u = "usuń plik", R = "button-fileChoose", g = "button-fileDetach", h = "button-fileDownload", j = "button-fileDisplay", r = "button-fileRemove";
function H({
  variant: t,
  canDownload: f,
  canDisplay: n,
  isDownloading: b,
  displayFile: T,
  detachFile: S,
  handleDownload: d,
  handleRemove: m
}) {
  return t === D ? /* @__PURE__ */ e(a, { children: /* @__PURE__ */ e(o, { dataTest: R, iconSize: 24, iconType: "ArrowRight", label: x }) }) : t === k ? /* @__PURE__ */ i(a, { "data-variant": t, children: [
    f && /* @__PURE__ */ e(
      o,
      {
        dataTest: h,
        disabled: b,
        iconSize: 24,
        iconType: "Download",
        label: p,
        onClick: d
      }
    ),
    /* @__PURE__ */ e(
      o,
      {
        dataTest: r,
        iconSize: 24,
        iconType: "Trash",
        label: u,
        onClick: m
      }
    )
  ] }) : t === y ? /* @__PURE__ */ i(a, { "data-variant": t, children: [
    n && /* @__PURE__ */ e(
      o,
      {
        dataTest: r,
        iconSize: 26,
        iconType: "Magnify",
        label: u,
        onClick: T
      }
    ),
    /* @__PURE__ */ e(
      o,
      {
        dataTest: r,
        iconSize: 26,
        iconType: "Trash",
        label: u,
        onClick: m
      }
    )
  ] }) : /* @__PURE__ */ i(B, { children: [
    /* @__PURE__ */ e(
      c,
      {
        dataTest: g,
        iconLeft: "Detach",
        onClick: S,
        size: s.S,
        variant: l.Ghost,
        children: n ? C : v
      }
    ),
    f && /* @__PURE__ */ e(
      c,
      {
        dataTest: h,
        iconLeft: "Download",
        onClick: d,
        size: s.S,
        variant: l.Ghost,
        children: p
      }
    ),
    n && /* @__PURE__ */ e(
      c,
      {
        dataTest: j,
        iconLeft: "Magnify",
        onClick: T,
        size: s.S,
        variant: l.Ghost,
        children: w
      }
    )
  ] });
}
export {
  z as F,
  H as a
};
