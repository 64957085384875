"use client";
import { jsx as r, jsxs as u } from "react/jsx-runtime";
import { useState as D, useRef as w } from "react";
import V from "./helpers/validate-file-policy/validate-file-policy.js";
import W from "./components/atoms/Modal/Modal.js";
import g from "./components/atoms/Button/Button.js";
import "./assets/icons/index.js";
import "./components/atoms/Button/Button.styles.js";
import { FileType as y } from "./@types/types.js";
import { StyledWrapper as $, StyledInput as k, StyledLabel as q, StyledButtonsBox as z } from "./components/molecules/ChooseFileFromDriveInput/ChooseFileFromDriveInput.styles.js";
import A from "./components/molecules/FileLoader/FileLoader.js";
import { inputDataTest as G, labelTextCvFile as H, labelTextExtra as J, labelText as K, modalDataTest as N, fileTypeTitle as Q, letterFileTypeDataTest as U, letterFileTypeLabel as X, otherFileTypeDataTest as Y, otherFileTypeLabel as Z } from "./components/molecules/ChooseFileFromDriveInput/ChooseFileFromDriveInput.constants.js";
var v = /* @__PURE__ */ ((a) => (a.Default = "default", a.CV = "cv", a))(v || {});
const { Default: _, CV: S } = v;
function ce({
  accept: a,
  checkTypes: F = !1,
  dataTest: x,
  filePolicies: C,
  isLoading: L,
  name: i,
  variant: s = _,
  onChange: h,
  onClick: p,
  onResetState: l
}) {
  const [m, c] = D(!1), [b, d] = D(!1), f = w(null), M = s === S, O = () => {
    var o;
    const e = (o = f.current) == null ? void 0 : o.files;
    if (!(e != null && e.length))
      return;
    const [t] = e, n = V({ file: t, filePolicy: C });
    if (F && !n) {
      d(!0);
      return;
    }
    h({ rawFile: t, fileErrors: n });
  }, T = (e) => () => {
    var o;
    const t = (o = f.current) == null ? void 0 : o.files;
    if (!(t != null && t.length))
      return;
    const [n] = t;
    d(!1), h({ rawFile: n, fileType: e });
  }, E = () => d(!1), I = (e) => {
    e.currentTarget.value = "", p == null || p(), l == null || l();
  }, P = (e) => {
    e.preventDefault(), e.stopPropagation(), e.currentTarget.value = "", c(!0), l == null || l();
  }, B = (e) => {
    e.preventDefault(), e.stopPropagation(), c(!1);
  }, j = () => {
    c(!1);
  };
  return L ? /* @__PURE__ */ r(A, { id: `${i}Loader`, value: 100 }) : /* @__PURE__ */ u($, { "data-test": x, "data-variant": s, isDragOver: m, children: [
    /* @__PURE__ */ r(
      k,
      {
        ref: f,
        accept: a,
        "data-test": G,
        id: i,
        name: i,
        onChange: O,
        onClick: I,
        onDragEnter: (e) => P(e),
        onDragLeave: (e) => B(e),
        onDrop: () => j(),
        type: "file"
      }
    ),
    /* @__PURE__ */ r(q, { "data-variant": s, htmlFor: i, isDragOver: m, children: M ? /* @__PURE__ */ r("p", { children: H }) : /* @__PURE__ */ u("span", { children: [
      /* @__PURE__ */ r("small", { children: J }),
      K
    ] }) }),
    b && /* @__PURE__ */ r(W, { dataTest: N, onClose: E, title: Q, children: /* @__PURE__ */ u(z, { children: [
      /* @__PURE__ */ r(g, { dataTest: U, onClick: T(y.Letter), children: X }),
      /* @__PURE__ */ r(g, { dataTest: Y, onClick: T(y.Other), children: Z })
    ] }) })
  ] });
}
export {
  ce as C,
  v as a
};
