import { jsxs as o, jsx as e } from "react/jsx-runtime";
import { useState as f } from "react";
import { StyledLabel as a, StyledExtraContent as x } from "./RichLabel.styles.js";
import p from "./RichLabelButton.js";
function H({ htmlFor: c, mainContent: t, extraContent: d, maxCharacters: n }) {
  const s = typeof t == "object", u = !s && n ? t.length > n : !1, [h, S] = f(u), [i, b] = f(!0);
  let l = t;
  return s || d ? /* @__PURE__ */ o("div", { children: [
    /* @__PURE__ */ o(a, { htmlFor: c, children: [
      l,
      i ? null : /* @__PURE__ */ e(x, { children: d })
    ] }),
    /* @__PURE__ */ e(
      p,
      {
        handleOnMoreButtonClick: () => b((r) => !r),
        isExtraContentHidden: i,
        withSpace: i
      }
    )
  ] }) : (l = h ? `${t.slice(0, n)}...` : t, /* @__PURE__ */ o("div", { children: [
    /* @__PURE__ */ e(a, { htmlFor: c, children: l }),
    u ? /* @__PURE__ */ e(
      p,
      {
        handleOnMoreButtonClick: () => S((r) => !r),
        isExtraContentHidden: h,
        withSpace: !0
      }
    ) : null
  ] }));
}
export {
  H as default
};
