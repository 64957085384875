import { s as e } from "../../../index-CARpW-rC.js";
import '../../../assets/Accordion.css';const l = /* @__PURE__ */ e("div")({
  name: "StyledAccordion",
  class: "s1ah7iqe",
  propsAsIs: !1
}), p = /* @__PURE__ */ e("div")({
  name: "StyledAccordionVisibleContentWrapper",
  class: "s71rpkl",
  propsAsIs: !1
}), r = /* @__PURE__ */ e("div")({
  name: "StyledAccordionVisibleContent",
  class: "syugawn",
  propsAsIs: !1
}), n = () => ({
  isOpen: s,
  height: o
}) => s ? o : "0px", t = () => ({
  isOpen: s
}) => s ? "visible" : "hidden", a = /* @__PURE__ */ e("div")({
  name: "StyledAccordionHiddenContent",
  class: "snjwbm8",
  propsAsIs: !1,
  vars: {
    "snjwbm8-0": [n()],
    "snjwbm8-1": [t()]
  }
}), c = () => ({
  isOpen: s
}) => s ? "-3px" : "6px", d = () => ({
  isOpen: s
}) => s ? "rotate(-180deg)" : "rotate(0)", A = /* @__PURE__ */ e("div")({
  name: "StyledAccordionButton",
  class: "s12pd2c4",
  propsAsIs: !1,
  vars: {
    "s12pd2c4-0": [c()],
    "s12pd2c4-1": [d()]
  }
});
export {
  l as StyledAccordion,
  A as StyledAccordionButton,
  a as StyledAccordionHiddenContent,
  r as StyledAccordionVisibleContent,
  p as StyledAccordionVisibleContentWrapper
};
