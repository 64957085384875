"use client";
import { useRef as i, useState as o, useCallback as a, useEffect as f } from "react";
import { defaultTimeout as c } from "./use-snackbar.constants.js";
function S(r = c) {
  const e = i(null), [n, u] = o(!1), s = a(
    (t) => {
      u(!0), e.current && clearTimeout(e.current), e.current = setTimeout(() => {
        t == null || t(), u(!1);
      }, r);
    },
    [r]
  );
  return f(() => () => {
    e.current && clearTimeout(e.current);
  }, []), {
    isSnackbarVisible: n,
    snackbarTimeout: r,
    handleShowSnackbar: s
  };
}
export {
  S as default
};
