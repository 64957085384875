import { jsxs as k, jsx as o } from "react/jsx-runtime";
import D from "../Loader/Loader.js";
import { c as M, B as N, a as O, b as P } from "../../../ButtonContent-DUgA__eh.js";
import { ButtonClass as V, StyledDisabledOnClick as g } from "./Button.styles.js";
function G({
  children: l,
  counter: s,
  dataTest: i,
  disabled: e,
  color: m = N.Default,
  variant: n = O.Primary,
  size: B = P.M,
  type: c = "button",
  iconCenteredWithText: f = !1,
  iconColor: p,
  iconLeft: u,
  iconRight: r,
  isLoading: a = !1,
  strictLine: b = !1,
  loadingLabel: C,
  onClick: x,
  onMouseEnter: y,
  onDisabledClick: d,
  href: t,
  target: j,
  rel: w,
  title: z,
  wide: S,
  tabIndex: T
}) {
  return /* @__PURE__ */ k(
    t ? "a" : "button",
    {
      className: V,
      "data-color": m,
      "data-iconcentered": f,
      "data-size": B,
      "data-test": i,
      "data-variant": n,
      "data-wide": S,
      "data-withicon": !!(u ?? r),
      disabled: e ?? a,
      href: t,
      onClick: x,
      onMouseEnter: y,
      tabIndex: T,
      title: z,
      type: t ? void 0 : c,
      target: t ? j : void 0,
      rel: t ? w : void 0,
      children: [
        /* @__PURE__ */ o(
          M,
          {
            counter: s,
            iconColor: p,
            iconLeft: u,
            iconRight: r,
            isLoading: a,
            strictLine: b,
            variant: n,
            children: l
          }
        ),
        e && d && /* @__PURE__ */ o(g, { onClick: d }),
        a && /* @__PURE__ */ o(D, { absolute: !0, label: C })
      ]
    }
  );
}
export {
  G as default
};
