import { jsx as e } from "react/jsx-runtime";
import { s as a } from "../../../index-CARpW-rC.js";
import r from "../../../assets/styles/theme.js";
import '../../../assets/Counter.css';var o = /* @__PURE__ */ ((t) => (t.Light = "Light", t.Dark = "Dark", t.Notification = "Notification", t))(o || {});
const c = () => ({
  variant: t
}) => {
  switch (t) {
    case "Dark":
      return r.colors.primary.default;
    case "Notification":
      return r.colors.complimentary.red;
    default:
      return r.colors.grayscale.white;
  }
}, l = () => ({
  variant: t
}) => t === "Light" ? r.colors.primary.default : r.colors.grayscale.white, i = () => ({
  variant: t
}) => t === "Light" ? r.colors.grayscale.white : r.colors.grayscale.disabledDark, n = () => ({
  variant: t
}) => t === "Light" ? r.colors.grayscale.disabledDark : r.colors.grayscale.white, d = /* @__PURE__ */ a("span")({
  name: "StyledCounter",
  class: "s1tw8yzh",
  propsAsIs: !1,
  vars: {
    "s1tw8yzh-0": [c()],
    "s1tw8yzh-1": [l()],
    "s1tw8yzh-2": [i()],
    "s1tw8yzh-3": [n()]
  }
}), h = "span-counter";
function u({
  value: t,
  variant: s = "Dark"
  /* Dark */
}) {
  return /* @__PURE__ */ e(d, {
    "data-test": h,
    variant: s,
    children: t
  });
}
export {
  o as CounterVariant,
  u as default
};
