import { jsx as s } from "react/jsx-runtime";
import { s as a } from "../../../index-CARpW-rC.js";
import n from "../Icon/Icon.js";
import '../../../assets/Loader.css';const r = () => ({
  absolute: t
}) => t ? "absolute" : "initial", d = () => ({
  absolute: t
}) => t ? "100%" : "max-content", c = /* @__PURE__ */ a("div")({
  name: "StyledLoader",
  class: "s1lky5mt",
  propsAsIs: !1,
  vars: {
    "s1lky5mt-0": [r()],
    "s1lky5mt-1": [d()]
  }
}), i = () => n, m = /* @__PURE__ */ a(i())({
  name: "StyledIcon",
  class: "s3eujy",
  propsAsIs: !0
}), p = "Wczytywanie", y = "div-loader";
function I({
  absolute: t = !1,
  color: e,
  className: o,
  label: l = p
}) {
  return /* @__PURE__ */ s(c, {
    absolute: t,
    "aria-label": l,
    className: o,
    color: e,
    "data-test": y,
    role: "status",
    children: /* @__PURE__ */ s(m, {
      color: e,
      iconType: "Loader"
    })
  });
}
export {
  I as default
};
