"use client";
import { jsx as c, jsxs as y, Fragment as D } from "react/jsx-runtime";
import { useRef as b, useState as S, useEffect as d } from "react";
import x from "../Icon/Icon.js";
import { StyledDropdown as M, StyledLoader as T, StyledHeader as v, StyledHeadline as A, StyledMessage as F } from "./Dropdown.styles.js";
const H = "Brak wyników", I = "list-dropdown";
function w(t) {
  var e;
  return Array.from(((e = t.current) == null ? void 0 : e.childNodes) || []).filter((o) => o.nodeName === "LI");
}
function j(t, e) {
  const r = w(e)[t];
  r instanceof HTMLLIElement && r.focus();
}
function P({
  children: t,
  isOpen: e,
  handleClose: o,
  headline: r,
  defaultMessage: f = H,
  isLoading: E = !1,
  isAutoSelect: h = !1,
  defaultSelectedOption: u = 0,
  dataTest: g = I
}) {
  const s = b(null), [n, l] = S(u > 0 ? u : 0), k = f && t.length === 0, L = (i) => {
    i.preventDefault();
    const a = w(s);
    switch (i.key) {
      case "ArrowDown":
        n < a.length - 1 && l(n + 1);
        break;
      case "ArrowUp":
        n > 0 && l(n - 1);
        break;
      case "Escape":
      case "Tab":
        o();
        break;
    }
  }, m = [n];
  h && m.push(e), d(() => j(n, s), m), d(() => l(0), [t.length]);
  const p = (i) => {
    var a;
    s.current && !((a = s.current.parentElement) != null && a.contains(i.target)) && o();
  };
  return d(() => (document.addEventListener("click", p, !e), () => document.removeEventListener("click", p, !e))), /* @__PURE__ */ c(M, { ref: s, "data-test": g, isOpen: e, role: "listbox", onKeyDown: L, children: E ? /* @__PURE__ */ c(T, {}) : /* @__PURE__ */ y(D, { children: [
    r && /* @__PURE__ */ y(v, { children: [
      /* @__PURE__ */ c(x, { iconType: "Magnify" }),
      /* @__PURE__ */ c(A, { children: r })
    ] }),
    t,
    k && /* @__PURE__ */ c(F, { children: f })
  ] }) });
}
export {
  P as default
};
