const a = {
  primary: {
    darker: "#313a87",
    default: "#4855c6",
    light: "#78ccfc",
    highlight: "#e9ebf8",
    background: "#f8f8fd",
    disabled: "#8a92db"
  },
  grayscale: {
    background: "#f6f6f8",
    dark: "#676b8d",
    disabledDark: "#8b8ea8",
    disabledLight: "#e4e5eb",
    veryDark: "#202557",
    white: "#ffffff"
  },
  complimentary: {
    green: "#208358",
    orange: "#B35C00",
    red: "#CF4457",
    purple: "#7B63D9",
    yellow: "#ffe481"
  }
}, e = {
  default: "0 0 12px rgba(0 0 0 / 12%)",
  small: "0 0 4.75px rgba(0 0 0 / 12%)",
  blue: "0 0 12px rgba(32 37 87 / 8%)"
}, r = {
  sansSerif: "var(--font-open-sans, 'Open Sans'), sans-serif",
  serif: "var(--font-work-sans, 'Work Sans'), serif"
}, f = {
  colors: a,
  fontFamily: r,
  shadows: e
};
export {
  f as default
};
