"use client";
import { jsxs as c, jsx as s } from "react/jsx-runtime";
import { memo as d, useState as f, useEffect as p } from "react";
import g from "../../../assets/styles/theme.js";
import { StyledProgressWrapper as P, StyledProgress as y, StyledProgressBarFallback as S } from "./ProgressBar.styles.js";
function h({
  color: r = g.colors.primary.default,
  animationDuration: o = 2e3,
  id: a,
  dataTest: u,
  height: m = 8,
  max: i = 100,
  value: e = 100
}) {
  const [t, l] = f(0);
  return p(() => {
    const n = setTimeout(() => l(e), 100);
    return () => clearTimeout(n);
  }, [e]), /* @__PURE__ */ c(P, { duration: o, children: [
    /* @__PURE__ */ s(y, { "data-test": u, height: m, id: a, max: i, value: t, color: r }),
    /* @__PURE__ */ s(S, { width: t, color: r })
  ] });
}
const V = d(h);
export {
  V as default
};
