"use client";
import { jsxs as o, jsx as f } from "react/jsx-runtime";
import { useRef as k, useState as C, useEffect as u } from "react";
import { StyledCircularProgressBar as I, StyledCircleBackground as B, StyledCircleValue as D, StyledValueText as O } from "./CircularProgressBar.styles.js";
import { size as d, padding as m, strokeWidth as i } from "./CircularProgressBar.constants.js";
function z({ value: r, ariaLabel: p, dataTest: x, from: c = 0 }) {
  const a = k(null), [g, h] = C(c), l = r > 100, n = (d - m - i) / 2, e = 2 * Math.PI * n, y = e - c / 100 * e;
  return u(() => {
    const s = setTimeout(() => {
      const t = a.current;
      if (t) {
        const S = e - (l ? 100 : r) / 100 * e;
        t.style.strokeDashoffset = S.toString();
      }
    }, 0);
    return () => clearTimeout(s);
  }, [r, e]), u(() => {
    const s = setInterval(() => {
      h((t) => l ? 100 : r > t ? t + 1 : r < t ? t - 1 : (clearInterval(s), t));
    }, 10);
    return () => clearInterval(s);
  }, [r]), /* @__PURE__ */ o(
    I,
    {
      "aria-label": p,
      "aria-valuemin": 0,
      "aria-valuemax": 100,
      "aria-valuenow": r,
      "aria-valuetext": `${r}%}`,
      "data-test": x,
      role: "progressbar",
      size: `${d}px`,
      padding: `${m}px`,
      children: [
        /* @__PURE__ */ o("svg", { children: [
          /* @__PURE__ */ f(B, { cx: "50%", cy: "50%", r: n, fill: "none", strokeWidth: i }),
          /* @__PURE__ */ f(
            D,
            {
              ref: a,
              cx: "50%",
              cy: "50%",
              r: n,
              strokeLinecap: "round",
              fill: "none",
              strokeWidth: i,
              strokeDasharray: e,
              strokeDashoffset: y
            }
          )
        ] }),
        /* @__PURE__ */ o(O, { children: [
          g,
          "%"
        ] })
      ]
    }
  );
}
export {
  z as default
};
