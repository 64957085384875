import { s } from "../../../index-CARpW-rC.js";
import '../../../assets/RichLabel.css';const e = /* @__PURE__ */ s("label")({
  name: "StyledLabel",
  class: "s1oedrxk",
  propsAsIs: !1
}), l = /* @__PURE__ */ s("button")({
  name: "StyledInlineButton",
  class: "s169sulc",
  propsAsIs: !1
}), n = /* @__PURE__ */ s("div")({
  name: "StyledExtraContent",
  class: "s1jxt3pe",
  propsAsIs: !1
});
export {
  n as StyledExtraContent,
  l as StyledInlineButton,
  e as StyledLabel
};
