import { s as e } from "../../../index-CARpW-rC.js";
import s from "../../../assets/styles/theme.js";
import o from "../../../assets/styles/zIndexes.js";
import t from "../Icon/Icon.js";
import c from "../IconButton/IconButton.js";
import '../../../assets/AlertBox.css';const a = () => ({
  fixedPositioned: r
}) => r ? "fixed" : "relative", n = () => ({
  fixedPositioned: r
}) => r ? o.globalAlertBox : o.alertBox, l = () => ({
  hasCloseButton: r
}) => r ? "32px 1fr 12px" : "32px 1fr", p = () => ({
  type: r
}) => r === "Legal" ? "16px 0" : "16px", x = () => ({
  noBorderRadius: r
}) => r ? "0" : "8px", u = () => ({
  type: r
}) => r === "Legal" ? "12px" : "14px", i = () => ({
  type: r
}) => {
  switch (r) {
    case "Success":
      return s.colors.complimentary.green;
    case "Error":
    case "ErrorGlobal":
      return s.colors.complimentary.red;
    case "Info":
      return s.colors.primary.default;
    case "InfoLight":
      return s.colors.primary.highlight;
    case "Legal":
      return "transparent";
    default:
      return s.colors.grayscale.background;
  }
}, m = () => ({
  type: r
}) => {
  switch (r) {
    case "Success":
    case "Error":
    case "ErrorGlobal":
    case "Info":
      return s.colors.grayscale.white;
    case "InfoLight":
      return s.colors.grayscale.dark;
    default:
      return s.colors.grayscale.dark;
  }
}, q = /* @__PURE__ */ e("div")({
  name: "StyledAlertBox",
  class: "s85q148",
  propsAsIs: !1,
  vars: {
    "s85q148-0": [a()],
    "s85q148-1": [n()],
    "s85q148-2": [l()],
    "s85q148-3": [p()],
    "s85q148-4": [x()],
    "s85q148-5": [u()],
    "s85q148-6": [i()],
    "s85q148-7": [m()]
  }
}), d = () => t, S = /* @__PURE__ */ e(d())({
  name: "StyledIcon",
  class: "s1ikur6",
  propsAsIs: !0
}), f = () => c, B = /* @__PURE__ */ e(f())({
  name: "StyledIconButton",
  class: "s1v90sd0",
  propsAsIs: !0
});
export {
  q as StyledAlertBox,
  S as StyledIcon,
  B as StyledIconButton
};
