import { s as l } from "../../../index-CARpW-rC.js";
import e from "../../../assets/styles/theme.js";
import t from "../../atoms/Icon/Icon.js";
import '../../../assets/Select.styles.css';/* empty css                                        */
const S = /* @__PURE__ */ l("div")({
  name: "StyledFieldWrapper",
  class: "s1a0vk7y",
  propsAsIs: !1
}), a = () => ({
  isOpen: s,
  hasError: r
}) => s ? e.colors.primary.default : r ? e.colors.complimentary.red : e.colors.grayscale.dark, o = () => ({
  isOpen: s
}) => s ? e.colors.primary.default : "transparnet", p = () => ({
  isEmpty: s
}) => s ? e.colors.grayscale.dark : e.colors.grayscale.veryDark, f = /* @__PURE__ */ l("select")({
  name: "StyledSelect",
  class: "stlllg7",
  propsAsIs: !1,
  vars: {
    "stlllg7-0": [a()],
    "stlllg7-1": [o()],
    "stlllg7-2": [p()]
  }
}), v = /* @__PURE__ */ l("div")({
  name: "StyledSelectWrapper",
  class: "sudosvv",
  propsAsIs: !1
}), g = /* @__PURE__ */ l("span")({
  name: "StyledOptionLabel",
  class: "ssy587a",
  propsAsIs: !1
}), c = () => t, n = () => ({
  isOpen: s
}) => s ? "rotate(180deg)" : "none", u = /* @__PURE__ */ l(c())({
  name: "StyledIcon",
  class: "s1ndrdvl",
  propsAsIs: !0,
  vars: {
    "s1ndrdvl-0": [n()]
  }
});
export {
  S as StyledFieldWrapper,
  u as StyledIcon,
  g as StyledOptionLabel,
  f as StyledSelect,
  v as StyledSelectWrapper
};
