"use client";
import { jsxs as p, jsx as t } from "react/jsx-runtime";
import { forwardRef as V, useMemo as l } from "react";
import { I as W, b as q } from "../../../Input-DJu9GhhH.js";
import { StyledInputWrapper as w } from "../../atoms/Input/Input.styles.js";
import C, { LabelVariant as n } from "../../atoms/Label/Label.js";
import S from "../../atoms/FieldWrapper/FieldWrapper.js";
import "../../atoms/FieldWrapper/FieldWrapper.styles.js";
import m, { HelperTextType as f } from "../../atoms/HelperText/HelperText.js";
const $ = "text-errorMessage", k = "text-hintMessage", Q = V(
  ({
    name: e,
    label: d,
    value: x,
    variant: s,
    readOnly: i = !1,
    required: c = !1,
    error: r = "",
    hint: a = "",
    placeholder: u = "",
    type: T = "text",
    inputSize: h,
    maxLength: I,
    autoComplete: H,
    onBlur: b,
    onChange: g,
    onFocus: y,
    onInvalid: D,
    onKeyDown: F,
    onKeyUp: M,
    isOpen: j,
    isInlineField: B,
    ...E
  }, L) => {
    const o = l(() => !!r, [r]), R = l(() => !!a, [a]);
    return /* @__PURE__ */ p(S, { "data-inline-field": B, "data-test": `field-${e}`, readOnly: i, children: [
      /* @__PURE__ */ p(w, { children: [
        /* @__PURE__ */ t(
          W,
          {
            hasError: o,
            isOpen: j,
            maxLength: I,
            autoComplete: H,
            name: e,
            onBlur: b,
            onChange: g,
            onFocus: y,
            onInvalid: D,
            onKeyDown: F,
            onKeyUp: M,
            inputSize: h,
            placeholder: u,
            readOnly: i,
            ref: L,
            type: T,
            value: x,
            variant: s,
            ...E
          }
        ),
        /* @__PURE__ */ t(
          C,
          {
            hasError: o,
            htmlFor: e,
            isRequired: c,
            variant: s === q.Compact ? n.Floating : n.Default,
            children: d
          }
        )
      ] }),
      o && /* @__PURE__ */ t(m, { dataTest: $, text: r, type: f.Error }),
      R && /* @__PURE__ */ t(m, { dataTest: k, text: a, type: f.Hint })
    ] });
  }
);
export {
  Q as default
};
