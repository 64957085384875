"use client";
import { jsx as y } from "react/jsx-runtime";
import { useRef as b, useCallback as g, useEffect as o } from "react";
import { StyledCheckbox as j } from "./Checkbox.styles.js";
function S({
  id: u,
  name: f,
  checked: t = !1,
  dataTest: n,
  indeterminate: a = !1,
  ariaLabel: i,
  value: p,
  required: e,
  onChange: s,
  hasError: m = !1
}) {
  const r = b(null), C = g(
    (l) => {
      l.stopPropagation(), r != null && r.current && (r.current.indeterminate = a && !t), s && s(l);
    },
    [s, a, t]
  );
  return o(() => {
    r != null && r.current && (r.current.checked = t);
  }, [t]), o(() => {
    r != null && r.current && (r.current.indeterminate = a && !t);
  }, [a, t]), /* @__PURE__ */ y(
    j,
    {
      ref: r,
      "aria-label": i,
      checked: t,
      "data-test": n ?? `checkbox-${u}`,
      id: u,
      name: f,
      onChange: C,
      required: e,
      type: "checkbox",
      value: p,
      "data-has_error": m
    }
  );
}
export {
  S as default
};
